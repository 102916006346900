import { createSlice } from '@reduxjs/toolkit';

import { IUser } from 'src/types/user';

import * as userThunks from '../thunks/users';

export interface UsersState {
  users: null | IUser[];
}

const initialState: UsersState = {
  users: []
};

const slice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    getUsers: (state, action) => {
      state.users = action.payload;
    },
    addUser: (state, action) => {
      state.users = [...state.users, ...action.payload];
    },
    updateUser: (state, action) => {
      const id = action.payload.id;
      const orgs = [...state.users];
      const index = orgs.findIndex((i) => i.id === id);
      if (index > -1) {
        orgs[index] = action.payload.data;
      }
      state.users = orgs;
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter((i) => i.id !== action.payload);
    }
  },
  extraReducers: () => (builder) => {
    builder.addCase(userThunks.getUserDetail.fulfilled, (state, { payload }) => {
      state.productGroups = payload;
    });
    builder.addCase(userThunks.createUser.fulfilled, (state, { payload }) => {
      state.productGroups = [...state.productGroups, payload];
      state.activeProduct = payload;
    });
  }
});

export const { addUser, updateUser, deleteUser, getUsers } = slice.actions;

export default slice.reducer;
