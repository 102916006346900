import Platform from 'platform';

import { actionPropertyOptions, providerOptions } from 'src/content/ProductGroups/data';
import { Rule } from 'src/types/rule';

export const isReqPass = (status: number): boolean => {
  return status >= 200 && status < 300;
};

export const getFormattedConditionsForRuleCreation = (conditions: Array<{ [key: string]: any }> = []): any[] => {
  const singleTypeConditions = conditions.filter((c) => c.type !== 'multi');
  const singleConditions = singleTypeConditions.map((c) => ({ ...c }));
  const allConditions = [];
  singleConditions.forEach((c) => {
    allConditions.push(c);
  });

  // console.log('allConditions:', allConditions);
  return allConditions.map((c) => ({
    fact: c.fieldName || c?.fact,
    operator: c.comparison || c?.operator,
    value: c.value,
    id: c?.id,
    valueType: c?.valueType,
    type: c?.type
  }));
};

export const getFormattedConditions = (conditions: Array<{ [key: string]: any }> = []): any[] => {
  if (conditions.length === 0) {
    return [];
  }
  return conditions;

  // const response = new Map();

  // for (const product of conditions) {
  //   const { fact } = product;
  //   if (!response.has(fact)) {
  //     response.set(fact, []);
  //   }
  //   response.get(fact).push(product);
  // }

  // console.log('response:', response.values());
  // return Array.from(response.values()).map((el) => {
  //   if (el.length > 1) {
  //     el[0] = { ...el[0], value: el.map((i) => i.value) };
  //     return el[0];
  //   }
  //   return el[0];
  // });
};

export const getProductGroupPayload = (
  values: { [key: string]: any },
  currentStep: number,
  query: string | string[],
  activeProductId: string
):
  | { pid: string; organization: any; name: any; description: any }
  | { id: string; body: { pid: string; organization: any; name: any; description: any } }
  | { id: string; body: { providers: any } }
  | object => {
  if (currentStep === 1) {
    if (!query) {
      return {
        pid: values?.pid,
        name: values?.name,
        description: values?.description,
        organization: values?.organization,
        apiValue: values?.apiValue?.includes('{,}') ? values?.apiValue?.split('{,}') : values?.apiValue,
        notifyEmail: values.notifyEmail,
        retentionPolicy:
          values?.retentionPeriod && values.retentionUnit && values.isRetentionTypeScheduled
            ? {
                number: values?.retentionPeriod,
                unit: values?.retentionUnit
              }
            : undefined,
        deletePIIImmediately: values.isRetentionTypeScheduled ? undefined : values?.deletePIIImmediately
      };
    }
    return {
      id: activeProductId,
      body: {
        pid: values?.pid,
        name: values?.name,
        description: values?.description,
        organization: values?.organization,
        apiValue: values?.apiValue?.includes('{,}') ? values?.apiValue?.split('{,}') : values?.apiValue,
        notifyEmail: values.notifyEmail,
        retentionPolicy:
          values?.retentionPeriod && values.retentionUnit && values.isRetentionTypeScheduled
            ? {
                number: values?.retentionPeriod,
                unit: values?.retentionUnit
              }
            : undefined,
        deletePIIImmediately: values.isRetentionTypeScheduled ? undefined : values?.deletePIIImmediately
      }
    };
  }
  if (currentStep === 2) {
    return {
      id: activeProductId,
      body: {
        providers: values?.providers?.map?.((p, index) => ({
          ...p,
          providerName:
            providerOptions?.find((i) => i?.value === p?.providerId || i.value === p?.providerName)?.value || '',
          providerId: p?.providerId || p?.providerName,
          sid: p?.sid,
          url: p?.url,
          isCompleted: true,
          providerRules: query ? p?.providerRules?.map((p) => p) : [],
          priority: index + 1
        }))
      }
    };
  }
  if (currentStep === 3) {
    return {
      id: activeProductId,
      body: {
        providers: values?.providers?.map?.((p, index) => {
          return {
            ...p,
            providerName:
              providerOptions?.find((i) => [p?.providerId, p?.providerName]?.includes(i?.value))?.label || '',
            providerId: p?.providerId || p?.providerName,
            sid: p?.sid,
            url: p?.url,
            isCompleted: true,
            priority: index + 1,
            providerRules: values?.providerRulesObj?.[p.providerName]?.map((rules) => rules._id),
            rulePriority: values?.providerRulesObj?.[p.providerName]?.reduce(
              (rule, key, index) => ({ ...rule, [key._id]: index + 1 }),
              {}
            )
          };
        })
      }
    };
  }
  return {};
};

export function formatRuleForStore(rule: Rule, comingFromAPI = true): any {
  const formattedConditions = getFormattedConditionsForRuleCreation(rule?.conditions.all);
  return {
    ...rule,
    conditions: formattedConditions,
    actions: rule.actions.update,
    isCompleted: comingFromAPI,
    id: (rule as any)._id,
    isSaved: comingFromAPI
  };
}
export const formattedProductData = (data?: { payload?: any }): any => {
  const policy = data?.payload?.retentionPolicy;
  return {
    ...(data?.payload || {}),
    providers: data?.payload?.providers?.map((provider) => ({
      ...provider,
      providerRules: provider?.providerRules?.map((rule) => rule?._id || rule)
    })),

    retentionUnit: policy?.unit,
    retentionPeriod: policy?.number,
    isRetentionTypeScheduled: policy?.unit && policy?.number,
    providerRulesObj: data?.payload?.providerRules.reduce(
      (obj, item) => (
        (obj[item.provider] = item.rules?.map?.((r, index) => {
          const formattedConditions = getFormattedConditions(r?.conditions?.all);
          return {
            ...r,
            conditions: formattedConditions,
            isCompleted: true,
            id: index + 1
          };
        })),
        obj
      ),
      {}
    ),
    providerRules: data?.payload?.providerRules?.map?.((p) => ({
      ...p,
      rules: p?.rules?.map?.((r, index) => {
        const formattedConditions = getFormattedConditions(r?.conditions?.all);
        return {
          ...r,
          conditions: formattedConditions,
          isCompleted: true,
          id: index + 1
        };
      })
    }))
  };
};

export const isIOS = Platform.os.family === 'iOS';
export const osVersion = Number(Platform.os.version);
export const isAndroid = Platform.os.family === 'Android';
export const isMobile = isIOS || isAndroid;

export const getAccessToken = (): string => {
  const user = JSON.parse(localStorage.getItem('userInfo') || '{}');
  return user?.sessionToken || '';
};

export const getRefreshToken = (): string => {
  const user = JSON.parse(localStorage.getItem('userInfo') || '{}');
  return user?.refreshToken || '';
};

export function tryConvertToDate(date: string, defaultValue?: string): Date | string | undefined {
  const convertedDate = new Date(date);
  if (convertedDate.toString() === 'Invalid Date') {
    return defaultValue || date;
  }
  return convertedDate;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getUserOrgId = (): string => {
  const user = JSON.parse(localStorage.getItem('userInfo') || '{}');
  return user?.apiKey || '';
};

export const showMoreOptions = (value: string): boolean => {
  const isNumberValue = actionPropertyOptions?.find((item) => item?.value === value);
  return isNumberValue?.number;
};

export const replaceUnderscore = (value: string): string => {
  return value?.replace('_', ' ');
};

export const filteredProvider = (providers: [{ enabled: boolean }]): any => providers;
// providers?.filter((provider) => provider?.enabled);
export const formattedOptions = (groupName: string, options: any[]): any[] => {
  return options
    ?.sort((a: { label: string }, b) => a?.label?.localeCompare?.(b?.label))
    ?.map((item) => ({
      groupName,
      ...item
    }));
};

export function camelCaseToTitleCase(str: string): string {
  // Add space before each capital letter
  const spacedStr = str.replace(/([A-Z])/g, ' $1');
  // Convert the first character of each word to uppercase
  return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
}
