import { createSlice } from '@reduxjs/toolkit';

export interface CommonState {
  apiHost: null | string;
}

const initialState: CommonState = {
  apiHost: localStorage.getItem('apiHost') || 'development'
};

const slice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setApiHost: (state, action) => {
      localStorage.setItem('apiHost', action.payload);
      state.apiHost = action.payload;
    }
  }
});

export const { setApiHost } = slice.actions;

export default slice.reducer;
