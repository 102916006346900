import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

import SuspenseLoader from '../SuspenseLoader';

interface Props {
  open: boolean;
  title: string;
  message: string;
  cancelTitle: string;
  confirmTitle: string;
  onClose: () => void;
  onConfirm: (e?: any) => void;
  disabled?: boolean;
}

const AlertDialog = ({
  open,
  title,
  message,
  cancelTitle,
  confirmTitle,
  onClose,
  onConfirm,
  disabled = false
}: Props) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => (disabled ? {} : onClose())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => (disabled ? {} : onClose())}>{cancelTitle}</Button>
          <Button onClick={onConfirm} autoFocus disabled={disabled}>
            {confirmTitle}
            {disabled ? <SuspenseLoader /> : null}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
