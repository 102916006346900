import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';

import UserManagement from './pages/users/manage';
import AddUser from './pages/users/manage/AddUser';

const Loader = (Component) => {
  const SuspenceComponent = (props) => (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
  return SuspenceComponent;
};

// Pages

const Overview = Loader(lazy(() => import('src/content/Overview')));

const Signup = Loader(lazy(() => import('src/pages/auth/signUp')));

const Login = Loader(lazy(() => import('src/pages/auth/login')));
// Dashboards

const Home = Loader(lazy(() => import('src/pages/home')));

const Dashboard = Loader(lazy(() => import('src/pages/dashboard')));

const HealthCheck = Loader(lazy(() => import('src/pages/reports/health-check')));
const TransactionDetails = Loader(lazy(() => import('src/pages/reports/transaction-details')));
const ManageOrganization = Loader(lazy(() => import('src/pages/organizations/manage')));
const AddOrganization = Loader(lazy(() => import('src/pages/organizations/manage/AddOrganization')));

const Profile = Loader(lazy(() => import('src/pages/users/detail')));
const UserDetail = Loader(lazy(() => import('src/content/Management/Users/detail')));
const TransactionDetail = Loader(lazy(() => import('src/content/Management/Transactions/detail')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/common/404')));
const ProductGroups = Loader(lazy(() => import('src/pages/product-groups')));
const AuthGroups = Loader(lazy(() => import('src/pages/product-groups/authentication')));
const AddAuthGroups = Loader(lazy(() => import('src/pages/product-groups/authentication/AddGroup')));
const ProductGroup = Loader(lazy(() => import('src/pages/product-groups/product-group-details')));

const AddProductGroups = Loader(lazy(() => import('src/pages/product-groups/add-product-group')));
const Authentications = Loader(lazy(() => import('src/pages/reports/authentications')));
const RulesStore = Loader(lazy(() => import('src/pages/rules-store')));
const AddRuleStore = Loader(lazy(() => import('src/pages/rules-store/CreateStore')));
const Customization = Loader(lazy(() => import('src/pages/customization')));
const Identity = Loader(lazy(() => import('src/pages/identities/Identity')));

export const unauthenticatedRoutes = () => [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'signup',
        element: <Signup />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'overview',
        element: <Navigate to={'/'} replace />
      },
      {
        path: '*',
        element: <Login />
      }
    ]
  }
];

const routes = () => [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'customization',
        element: <Customization />
      },
      {
        path: 'signup',
        element: <Signup />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'overview',
        element: <Navigate to={'/'} replace />
      }
    ]
  },

  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'authentications',
        element: <Authentications />
      },
      {
        path: 'authentication-groups',
        element: <AuthGroups />
      },
      {
        path: 'authentication-groups/add-authentication-group',
        element: <AddAuthGroups />
      },
      {
        path: 'rules-store',
        element: <RulesStore />
      },
      {
        path: 'rules-store/create',
        element: <AddRuleStore />
      }
    ]
  },

  {
    path: 'identity',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={'product-groups'} replace />
      },

      {
        path: 'product-groups',
        element: <ProductGroups />
      },
      {
        path: 'product-groups/:id',
        element: <ProductGroup />
      },
      {
        path: 'product-groups/:id/:version',
        element: <ProductGroup />
      },
      {
        path: 'product-groups/add-product-group',
        element: <AddProductGroups />
      },
      {
        path: ':id',
        element: <Identity />
      }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      {
        path: 'manage',
        element: <UserManagement />
      },
      {
        path: 'add-user',
        element: <AddUser />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  },
  {
    path: 'organizations',
    element: <SidebarLayout />,
    children: [
      {
        path: 'manage',
        element: <ManageOrganization />
      },
      {
        path: 'add-organization',
        element: <AddOrganization />
      }
    ]
  },
  {
    path: '/reports',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to={'health-check'} replace />
      },
      {
        path: 'health-check',
        element: <HealthCheck />
      },
      {
        path: 'transaction-details',
        element: <TransactionDetails />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: 'users/detail/:id',
        element: <UserDetail />
      },
      {
        path: 'transactions/detail/:transactionId',
        element: <TransactionDetail />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default routes;
