import { DefaultProvider, DefaultProviderRule } from 'src/types/product-groups';

import { Option } from './AddProductGroup/Steps/fourth';

const WATCH_LIST_RESULT = 'watch_list_result';
export const steps = [
  {
    step: 'Step 1',
    title: 'Add Product Group'
  },
  {
    step: 'Step 2',
    title: 'Add Providers'
  },
  {
    step: 'Step 3',
    title: 'Add Rules'
  }
  // {
  //   step: 'Step 4',
  //   title: 'Add Rules'
  // }
];

export const providerOptions = [
  {
    label: 'Aristotle',
    value: 'ARISTOTLE'
  },
  {
    label: 'Idology',
    value: 'IDOLOGY'
  },
  {
    label: 'AUTHENTIC ID',
    value: 'AUTHENTIC_ID'
  },
  {
    label: 'IDEMIA',
    value: 'IDEMIA'
  },
  {
    label: 'Amazon Textract',
    value: 'AMAZON_TEXTRACT'
  },
  {
    label: 'Azure Form Recognizer',
    value: 'AZURE_FORM_RECOGNIZER'
  },
  {
    label: 'Open Sanctions',
    value: 'OPEN_SANCTIONS'
  },
  {
    label: 'IDEMIA SOR TXT',
    value: 'IDEMIA_SOR_TXT'
  },
  {
    label: 'Validation',
    value: 'VALIDATION'
  },
  {
    label: 'Property Exclusions',
    value: 'PROPERTY_EXCLUSIONS'
  },
  {
    label: 'Passkey Registration',
    value: 'PASSKEY_REGISTRATION'
  },
  {
    label: 'EVALUATE1',
    value: 'EVALUATE1'
  },
  {
    label: 'EVALUATE2',
    value: 'EVALUATE2'
  },
  {
    label: 'EVALUATE3',
    value: 'EVALUATE3'
  },
  {
    label: 'EVALUATE4',
    value: 'EVALUATE4'
  },
  {
    label: 'EVALUATE5',
    value: 'EVALUATE5'
  },
  {
    label: 'EVALUATE6',
    value: 'EVALUATE6'
  }
];

export const aristotleOptions: Option[] = [
  {
    label: 'Result Status',
    value: 'consumer_intelligence--aristotle--consumer_intelligence_result_status',
    type: 'multiple',
    option: [
      {
        value: '100',
        label: 'Success'
      },
      {
        value: '901',
        label: 'Failed: General Error'
      },
      {
        value: '905',
        label: 'Failed: Temporarily Unavailable'
      },
      {
        value: '906',
        label: 'Failed: Access'
      },
      {
        value: '907',
        label: 'Failed: Verification'
      },
      {
        value: '908',
        label: 'Failed: Internal Error'
      },
      {
        value: '910',
        label: 'Failed: Incomplete'
      },
      {
        value: '911',
        label: 'Failed: Duplicate Entities of Same Type'
      },
      {
        value: '912',
        label: 'Failed: Invalid Parameter'
      },
      {
        value: '920',
        label: 'Failed: Unknown Session'
      },
      {
        value: '921',
        label: 'Failed: Invalid SHA Hash'
      },
      {
        value: '922',
        label: 'Failed: Invalid E-mail Address'
      },
      {
        value: '923',
        label: 'Failed: Invalid Telephone Number'
      },
      {
        value: '924',
        label: 'Failed: Invalid Device ID'
      },
      {
        value: '925',
        label: 'Failed: Invalid IP Address Parameter'
      },
      {
        value: '926',
        label: 'Failed: Invalid Account Number'
      },
      {
        value: '927',
        label: 'Failed: Missing or Invalid City'
      },
      {
        value: '928',
        label: 'Failed: Missing or Invalid Postal Code'
      },
      {
        value: '929',
        label: 'Failed: Missing or Invalid Country Code'
      },
      {
        value: '930',
        label: 'Failed: Missing or Invalid Currency Code'
      },
      {
        value: '931',
        label: 'Failed: Missing or Invalid Amount'
      },
      {
        value: '932',
        label: 'Failed: Missing, Invalid or Mismatched Payment Type'
      },
      {
        value: '933',
        label: 'Failed: Missing or Invalid Merchant Acknowledgment'
      },
      {
        value: '934',
        label: 'Failed: Missing POST'
      },
      {
        value: '935',
        label: 'Failed: Invalid Shopping Cart'
      },
      {
        value: '936',
        label: 'Failed: Missing or Invalid Product Type'
      },
      {
        value: '937',
        label: 'Failed: Missing or Invalid Product Item'
      },
      {
        value: '938',
        label: 'Failed: Missing or Invalid Product Description'
      },
      {
        value: '939',
        label: 'Failed: Missing or Invalid Product Quantity'
      },
      {
        value: '940',
        label: 'Failed: Missing or Invalid Product Price'
      },
      {
        value: '913',
        label: 'Failed: Consumer Not Found'
      },
      {
        value: '914',
        label: 'Failed: Security Alert'
      },
      {
        value: '941',
        label: 'Failed: Missing or Invalid SSN'
      },
      {
        value: '942',
        label: "Failed: Missing or Invalid Driver's License Number or State"
      },
      {
        value: '943',
        label: 'Failed: Missing or Invalid Date of Birth'
      },
      {
        value: '960',
        label: 'Failed: Registering Mobile Device'
      },
      {
        value: '961',
        label: 'Failed: Locating Mobile Device'
      }
    ]
  },
  {
    label: 'Patriot Act List - Politically Exposed Persons',
    value: 'consumer_intelligence--aristotle--consumer_intelligence_pa_list_politically_exposed_persons',
    type: 'single',
    option: [
      {
        value: 'Y',
        label: 'True'
      },
      {
        value: 'N',
        label: 'False'
      }
    ]
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_credit_trade_number_canada',
    label: 'Credit Trade Number - Canada',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_data_source_type',
    label: 'Data Source Type',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_deceased',
    label: 'Deceased',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_error_message',
    label: 'Error Message',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_found_multiple_records',
    label: 'Found Multiple Records',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_matched_records_sources',
    label: "Matched Records' Sources",
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_pa_list_office_of_foreign_asset_control',
    label: 'Patriot Act List - Office of Foreign Asset Control',
    type: 'single',
    option: [
      {
        value: 'Y',
        label: 'True'
      },
      {
        value: 'N',
        label: 'False'
      }
    ]
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_primary_result',
    label: 'Primary Result',
    type: 'multiple',
    option: [
      {
        value: '0',
        label: '0: No Match'
      },
      {
        value: '2',
        label: '2: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '3',
        label: '3: First/Last/Home Address/Home city/Home State/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '4',
        label: '4: First/Last/Home Address/Home city/Home State/Home\n    Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '5',
        label: '5: First/Last/Suffix/Mail Address/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '6',
        label: '6: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone4/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '10',
        label: '10: First/Last/Suffix/Mail Address/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '15',
        label: '15: First/Last/Suffix/Mail Address/Mail Zip/DOB(yyyy)'
      },
      {
        value: '20',
        label: '20: First/Last/Suffix/Mail Address/Mail Zip/DOB(yyy)'
      },
      {
        value: '24',
        label: '24: First/Last/Mail Address/Mail Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '25',
        label: '25: First/Last/Mail Address/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '26',
        label: '26: First Initial/Last/Mail Address/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '29',
        label: '29: First/Last/Mail Address/Mail Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '30',
        label: '30: First/Last/Mail Address/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '31',
        label: '31: First Initial/Last/Mail Address/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '34',
        label: '34: First/Last/Mail Address/Mail Zip/DOB(yyyy)/ID'
      },
      {
        value: '35',
        label: '35: First/Last/Mail Address/Mail Zip/DOB(yyyy)'
      },
      {
        value: '36',
        label: '36: First Initial/Last/Mail Address/Mail Zip/DOB(yyyy)'
      },
      {
        value: '39',
        label: '39: First/Last/Mail Address/Mail Zip/DOB(yyy)/ID'
      },
      {
        value: '40',
        label: '40: First/Last/Mail Address/Mail Zip/DOB(yyy)'
      },
      {
        value: '41',
        label: '41: First Initial/Last/Mail Address/Mail Zip/DOB(yyy)'
      },
      {
        value: '45',
        label: '45: First/Last/Suffix/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '50',
        label: '50: First/Last/Suffix/Home Address/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '55',
        label: '55: First/Last/Suffix/Home Address/Home Zip/DOB(yyyy)'
      },
      {
        value: '60',
        label: '60: First/Last/Suffix/Home Address/Home Zip/DOB(yyy)'
      },
      {
        value: '64',
        label: '64: First/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '65',
        label: '65: First/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '66',
        label: '66: First Initial/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '69',
        label: '69: First/Last/Home Address/Home Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '70',
        label: '70: First/Last/Home Address/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '71',
        label: '71: First Initial/Last/Home Address/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '74',
        label: '74: First/Last/Home Address/Home Zip/DOB(yyyy)/ID'
      },
      {
        value: '75',
        label: '75: First/Last/Home Address/Home Zip/DOB(yyyy)'
      },
      {
        value: '76',
        label: '76: First Initial/Last/Home Address/Home Zip/DOB(yyyy)'
      },
      {
        value: '79',
        label: '79: First/Last/Home Address/Home Zip/DOB(yyy)/ID'
      },
      {
        value: '80',
        label: '80: First/Last/Home Address/Home Zip/DOB(yyy)'
      },
      {
        value: '81',
        label: '81: First Initial/Middle Initial/Last/Mail Address/Mail Zip/DOB(yyy)'
      },
      {
        value: '85',
        label: '85: First/Last/Suffix/Mail Address/Mail Zip'
      },
      {
        value: '90',
        label: '90: First/Last/Suffix/Home Address/Home Zip'
      },
      {
        value: '95',
        label: '95: First/Last/Mail Address/Mail Zip'
      },
      {
        value: '96',
        label: '96: First/Middle/Last/Home Address/Home city/Home Zip'
      },
      {
        value: '97',
        label: '97: First Initial/Middle/Last/Home Address/Home city/Home Zip'
      },
      {
        value: '98',
        label: '98: First/Last/Home Address/Home city/Home Zip'
      },
      {
        value: '99',
        label: '99: First Initial/Last/Home Address/Home city/Home Zip'
      },
      {
        value: '100',
        label: '100: First/Last/Home Address/Home Zip'
      },
      {
        value: '102',
        label: '102: First Initial/Last/Mail Address/Mail Zip'
      },
      {
        value: '103',
        label: '103: First Initial/Last/Home Address/Home Zip'
      },
      {
        value: '105',
        label: '105: First/Last/Suffix/Mail Zip/Mail Zip4/DOB(mm/dd/yyyy)'
      },
      {
        value: '110',
        label: '110: First/Last/Suffix/Mail Zip/Mail Zip4/DOB(mm/yyyy)'
      },
      {
        value: '115',
        label: '115: First/Last/Suffix/Mail Zip/Mail Zip4/DOB(yyyy)'
      },
      {
        value: '120',
        label: '120: First/Last/Suffix/Mail Zip/Mail Zip4/DOB(yyy)'
      },
      {
        value: '125',
        label: '125: First/Last/Mail Zip/Mail Zip4/DOB(mm/dd/yyyy)'
      },
      {
        value: '130',
        label: '130: First/Last/Mail Zip/Mail Zip4/DOB(mm/yyyy)'
      },
      {
        value: '135',
        label: '135: First/Last/Mail Zip/Mail Zip4/DOB(yyyy)'
      },
      {
        value: '140',
        label: '140: First/Last/Mail Zip/Mail Zip4/DOB(yyy)'
      },
      {
        value: '144',
        label: '144: First/Last/Mail Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '145',
        label: '145: First/Last/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '149',
        label: '149: First/Last/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '150',
        label: '150: First/Last/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '154',
        label: '154: First/Last/Mail Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '155',
        label: '155: First/Last/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '156',
        label: '156: First/Last/Gender/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '159',
        label: '159: First/Last/Home Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '160',
        label: '160: First/Last/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '164',
        label: '164: First/Last/Mail Zip/DOB(yyyy)/ID'
      },
      {
        value: '165',
        label: '165: First/Last/Mail Zip/DOB(yyyy)'
      },
      {
        value: '169',
        label: '169: First/Last/Home Zip/DOB(yyyy)/ID'
      },
      {
        value: '170',
        label: '170: First/Last/Home Zip/DOB(yyyy)'
      },
      {
        value: '174',
        label: '174: First/Last/Mail Zip/DOB(yyy)/ID'
      },
      {
        value: '175',
        label: '175: First/Last/Mail Zip/DOB(yyy)'
      },
      {
        value: '180',
        label: '180: First/Last/Home Zip/DOB(yyy)'
      },
      {
        value: '185',
        label: '185: First/Last/Suffix/Mail Zip/Mail Zip4'
      },
      {
        value: '190',
        label: '190: First/Last/Mail Zip/Mail Zip4'
      },
      {
        value: '195',
        label: '195: First Initial/Last/Gender/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '199',
        label: '199: First/Last/Gender/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '200',
        label: '200: First Initial/Last/Gender/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '205',
        label: '205: First Initial/Last/Gender/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '209',
        label: '209: First/Last/Gender/Home Address/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '210',
        label: '210: First Initial/Last/Gender/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '215',
        label: '215: First Initial/Last/Gender/Mail Zip/DOB(yyyy)'
      },
      {
        value: '219',
        label: '219: First/Last/Gender/Home Address/Home Zip/DOB(yyyy)'
      },
      {
        value: '220',
        label: '220: First Initial/Last/Gender/Home Zip/DOB(yyyy)'
      },
      {
        value: '225',
        label: '225: First Initial/Last/Gender/Mail Zip/DOB(yyy)'
      },
      {
        value: '230',
        label: '230: First Initial/Last/Gender/Home Zip/DOB(yyy)'
      },
      {
        value: '235',
        label: '235: First Initial/Last/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '240',
        label: '240: First Initial/Last/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '245',
        label: '245: First Initial/Last/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '250',
        label: '250: First Initial/Last/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '255',
        label: '255: First Initial/Last/Mail Zip/DOB(yyyy)'
      },
      {
        value: '260',
        label: '260: First Initial/Last/Home Zip/DOB(yyyy)'
      },
      {
        value: '265',
        label: '265: First Initial/Last/Mail Zip/DOB(yyy)'
      },
      {
        value: '270',
        label: '270: First Initial/Last/Home Zip/DOB(yyy)'
      },
      {
        value: '271',
        label: '271: First/Last/Gender/Home Address/Home Zip'
      },
      {
        value: '275',
        label: '275: First/Last/Gender/Mail Zip'
      },
      {
        value: '280',
        label: '280: First/Last/Gender/Home Zip'
      },
      {
        value: '285',
        label: '285: Last/Gender/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '290',
        label: '290: Last/Gender/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '295',
        label: '295: Last/Gender/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '300',
        label: '300: Last/Gender/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '305',
        label: '305: Last/Gender/Mail Zip/DOB(yyyy)'
      },
      {
        value: '310',
        label: '310: Last/Gender/Home Zip/DOB(yyyy)'
      },
      {
        value: '315',
        label: '315: Last/Gender/Mail Zip/DOB(yyy)'
      },
      {
        value: '319',
        label: '319: Last/Home Address/Home city/Home Zip'
      },
      {
        value: '320',
        label: '320: Last/Gender/Home Zip/DOB(yyy)'
      },
      {
        value: '321',
        label: '321: Last/Mail Address/Mail Zip'
      },
      {
        value: '322',
        label: '322: Last/Home Address/Home Zip'
      },
      {
        value: '323',
        label: '323: First/Last/Home Address/Home city'
      },
      {
        value: '324',
        label: '324: First Initial/Last/Home Address/Home city'
      },
      {
        value: '325',
        label: '325: First/Last/Mail Zip'
      },
      {
        value: '326',
        label: '326: Last/Home Address/Home city'
      },
      {
        value: '329',
        label: '329: First/Last/Home city/Home Zip'
      },
      {
        value: '330',
        label: '330: First/Last/Home Zip'
      },
      {
        value: '332',
        label: '332: First/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '333',
        label: '333: First/Mail Address/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '334',
        label: '334: Last/Mail Address/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '335',
        label: '335: Last/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '340',
        label: '340: Last/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '345',
        label: '345: Last/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '350',
        label: '350: Last/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '355',
        label: '355: Last/Mail Zip/DOB(yyyy)'
      },
      {
        value: '360',
        label: '360: Last/Home Zip/DOB(yyyy)'
      },
      {
        value: '364',
        label: '364: Last/Home city/DOB(mm/dd/yyyy)'
      },
      {
        value: '365',
        label: '365: Last/Mail Zip/DOB(yyy)'
      },
      {
        value: '366',
        label: '366: Last/Home city/DOB(mm/yyyy)'
      },
      {
        value: '367',
        label: '367: Last/Home city/DOB(yyyy)'
      },
      {
        value: '368',
        label: '368: First/Last/Home Address/Home city/Home State'
      },
      {
        value: '369',
        label: '369: First Initial/Last/Home Address/Home city/Home State'
      },
      {
        value: '370',
        label: '370: Last/Home Zip/DOB(yyy)'
      },
      {
        value: '371',
        label: '371: Last/Home Address/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '372',
        label: '372: First Initial/Last/Gender/Home Address/Home State/DOB(mm/yyyy)'
      },
      {
        value: '373',
        label: '373: First Initial/Last/Gender/Home State/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '374',
        label: '374: First/Last/Gender/Home State/Phone/DOB(mm/yyyy)'
      },
      {
        value: '375',
        label: '375: First/Last/Mail State/DOB(mm/dd/yyyy)'
      },
      {
        value: '376',
        label: '376: First/Last/Home Address/Home State'
      },
      {
        value: '377',
        label: '377: First Initial/Last/Home Address/Home State'
      },
      {
        value: '378',
        label: '378: First/Last/Mail Address/Mail State'
      },
      {
        value: '379',
        label: '379: First Initial/Last/Mail Address/Mail State'
      },
      {
        value: '380',
        label: '380: First/Last/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '381',
        label: '381: First/Last/Home State/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '382',
        label: '382: First/Last/Home State/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '383',
        label: '383: First/Last/Home State/Phone/DOB(mm/yyyy)'
      },
      {
        value: '384',
        label: '384: First/Last/Home State/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '385',
        label: '385: First/Last/Mail State/DOB(mm/yyyy)'
      },
      {
        value: '386',
        label: '386: First/Last/Home State/Phone/DOB(yyyy)'
      },
      {
        value: '387',
        label: '387: First/Last/Home State/Phone4/DOB(yyyy)'
      },
      {
        value: '388',
        label: '388: First Initial/Last/Home State/Phone/DOB(mm/yyyy)'
      },
      {
        value: '389',
        label: '389: First Initial/Last/Home State/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '390',
        label: '390: First/Last/Home State/DOB(mm/yyyy)'
      },
      {
        value: '391',
        label: '391: First Initial/Last/Home State/Phone/DOB(yyyy)'
      },
      {
        value: '392',
        label: '392: First Initial/Last/Home State/Phone4/DOB(yyyy)'
      },
      {
        value: '393',
        label: '393: First Initial/Last/Home Zip/Phone'
      },
      {
        value: '394',
        label: '394: First Initial/Last/Home Zip/Phone4'
      },
      {
        value: '395',
        label: '395: First/Last/Mail State/DOB(yyyy)'
      },
      {
        value: '396',
        label: '396: Last/Home Zip/Phone'
      },
      {
        value: '397',
        label: '397: Last/Home Zip/Phone4'
      },
      {
        value: '398',
        label: '398: First Initial/Last/Phone'
      },
      {
        value: '399',
        label: '399: First Initial/Last/Phone4'
      },
      {
        value: '400',
        label: '400: First/Last/Home State/DOB(yyyy)'
      },
      {
        value: '405',
        label: '405: First/Last/Mail State/DOB(yyy)'
      },
      {
        value: '410',
        label: '410: First/Last/Home State/DOB(yyy)'
      },
      {
        value: '411',
        label: '411: First/Last/Gender/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '412',
        label: '412: Last/Gender/Home Address/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '413',
        label: '413: First/Last/Gender/Home State/DOB(mm/yyyy)'
      },
      {
        value: '415',
        label: '415: First Initial/Last/Gender/Mail State/DOB(mm/dd/yyyy)'
      },
      {
        value: '420',
        label: '420: First Initial/Last/Gender/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '425',
        label: '425: First Initial/Last/Gender/Mail State/DOB(mm/yyyy)'
      },
      {
        value: '430',
        label: '430: First Initial/Last/Gender/Home State/DOB(mm/yyyy)'
      },
      {
        value: '435',
        label: '435: First Initial/Last/Gender/Mail State/DOB(yyyy)'
      },
      {
        value: '440',
        label: '440: First Initial/Last/Gender/Home State/DOB(yyyy)'
      },
      {
        value: '445',
        label: '445: First Initial/Last/Gender/Mail State/DOB(yyy)'
      },
      {
        value: '450',
        label: '450: First Initial/Last/Gender/Home State/DOB(yyy)'
      },
      {
        value: '455',
        label: '455: First Initial/Last/Mail State/DOB(mm/dd/yyyy)'
      },
      {
        value: '460',
        label: '460: First Initial/Last/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '461',
        label: '461: First Initial/Last/Home State/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '462',
        label: '462: First Initial/Last/Home State/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '465',
        label: '465: First Initial/Last/Mail State/DOB(mm/yyyy)'
      },
      {
        value: '470',
        label: '470: First Initial/Last/Home State/DOB(mm/yyyy)'
      },
      {
        value: '475',
        label: '475: First Initial/Last/Mail State/DOB(yyyy)'
      },
      {
        value: '480',
        label: '480: First Initial/Last/Home State/DOB(yyyy)'
      },
      {
        value: '485',
        label: '485: First Initial/Last/Mail State/DOB(yyy)'
      },
      {
        value: '490',
        label: '490: First Initial/Last/Home State/DOB(yyy)'
      },
      {
        value: '491',
        label: '491: First Initial/Last/Gender/Home Address/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '492',
        label: '492: First Initial/Last/Gender/Mail Zip/Mail Zip4/DOB(mm/yyyy)'
      },
      {
        value: '495',
        label: '495: First Initial/Last/Gender/Mail Zip'
      },
      {
        value: '500',
        label: '500: First Initial/Last/Gender/Home Zip'
      },
      {
        value: '505',
        label: '505: First Initial/Last/Mail Zip'
      },
      {
        value: '509',
        label: '509: First Initial/Last/Home city/Home Zip'
      },
      {
        value: '510',
        label: '510: First Initial/Last/Home Zip'
      },
      {
        value: '515',
        label: '515: Last/Gender/Mail Zip'
      },
      {
        value: '520',
        label: '520: Last/Gender/Home Zip'
      },
      {
        value: '525',
        label: '525: Gender/Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '530',
        label: '530: Gender/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '535',
        label: '535: Gender/Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '540',
        label: '540: Gender/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '545',
        label: '545: Gender/Mail Zip/DOB(yyyy)'
      },
      {
        value: '550',
        label: '550: Gender/Home Zip/DOB(yyyy)'
      },
      {
        value: '555',
        label: '555: Gender/Mail Zip/DOB(yyy)'
      },
      {
        value: '560',
        label: '560: Gender/Home Zip/DOB(yyy)'
      },
      {
        value: '565',
        label: '565: Last/Mail Zip'
      },
      {
        value: '567',
        label: '567: Last/Home city/Home Zip'
      },
      {
        value: '568',
        label: '568: First/Last/Home city/Home State'
      },
      {
        value: '569',
        label: '569: First Initial/Last/Home city/Home State'
      },
      {
        value: '570',
        label: '570: Last/Home Zip'
      },
      {
        value: '571',
        label: '571: First/Last/Mail State'
      },
      {
        value: '572',
        label: '572: First/Last/Home State'
      },
      {
        value: '573',
        label: '573: First Initial/Last/Mail State'
      },
      {
        value: '574',
        label: '574: First Initial/Last/Home State'
      },
      {
        value: '575',
        label: '575: Mail Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '580',
        label: '580: Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '585',
        label: '585: Mail Zip/DOB(mm/yyyy)'
      },
      {
        value: '590',
        label: '590: Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '595',
        label: '595: Mail Zip/DOB(yyyy)'
      },
      {
        value: '600',
        label: '600: Home Zip/DOB(yyyy)'
      },
      {
        value: '605',
        label: '605: Mail Zip/DOB(yyy)'
      },
      {
        value: '610',
        label: '610: Home Zip/DOB(yyy)'
      },
      {
        value: '611',
        label: '611: Home Address/Home Zip/Phone'
      },
      {
        value: '612',
        label: '612: Home Address/Home Zip/Phone4'
      },
      {
        value: '613',
        label: '613: Home Zip/Phone'
      },
      {
        value: '614',
        label: '614: Home Zip/Phone4'
      },
      {
        value: '615',
        label: '615: Home Address/Phone'
      },
      {
        value: '616',
        label: '616: Home Address/Phone4'
      },
      {
        value: '617',
        label: '617: Home Address/Home city/Home Zip'
      },
      {
        value: '618',
        label: '618: Home Address/Home Zip'
      },
      {
        value: '621',
        label: '621: First Initial/Last/DOB(mm/yyyy)'
      },
      {
        value: '622',
        label: '622: First Initial/Last/DOB(yyyy)'
      },
      {
        value: '623',
        label: '623: First/Last/Home city/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '624',
        label: '624: First/Last/Home city/Home State/DOB(mm/yyyy)'
      },
      {
        value: '625',
        label: '625: First/Last/Home city/Home State/DOB(yyyy)'
      },
      {
        value: '627',
        label: '627: First Initial/Last/Home city/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '628',
        label: '628: First Initial/Last/Home city/Home State/DOB(mm/yyyy)'
      },
      {
        value: '629',
        label: '629: First Initial/Last/Home city/Home State/DOB(yyyy)'
      },
      {
        value: '630',
        label: '630: First/Last/Home city/DOB(mm/dd/yyyy)'
      },
      {
        value: '640',
        label: '640: Last/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '650',
        label: '650: First/Middle/Last/Home Address/Home city/Home State/Home\n    Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '651',
        label: '651: First/Last/Home Address/Home city/Home State/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '653',
        label: '653: First/Last/Home Address/Home city/Home State/Home Zip/DOB(mm/yyyy)'
      },
      {
        value: '655',
        label: '655: First/Last/Home Address/Home city/Home State/Home Zip/DOB(yyyy)'
      },
      {
        value: '696',
        label: '696: First/Last/Home Address/Home city/Home Zip/Phone'
      },
      {
        value: '697',
        label: '697: First Initial/Last/Home Address/Home city/Home Zip/Phone'
      },
      {
        value: '698',
        label: '698: First/Last/Home Address/Home city/Home Zip/Phone4'
      },
      {
        value: '699',
        label: '699: First Initial/Last/Home Address/Home city/Home Zip/Phone4'
      },
      {
        value: '700',
        label: '700: First/Last'
      },
      {
        value: '701',
        label: '701: First/Last/Gender'
      },
      {
        value: '702',
        label: '702: First/Last/Gender/ID'
      },
      {
        value: '703',
        label: '703: First/Last/Gender/Home Address/ID'
      },
      {
        value: '704',
        label: '704: First/Last/Gender/Home Address/Home Zip/ID'
      },
      {
        value: '705',
        label: '705: First/Last/ID'
      },
      {
        value: '706',
        label: '706: First/Last/Home Address'
      },
      {
        value: '707',
        label: '707: Last/Home Address'
      },
      {
        value: '708',
        label: '708: First/Last/Home Zip'
      },
      {
        value: '709',
        label: '709: First/Last/Home Zip/ID'
      },
      {
        value: '710',
        label: '710: Last/ID'
      },
      {
        value: '711',
        label: '711: Last/Home Zip/ID'
      },
      {
        value: '712',
        label: '712: Last/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '713',
        label: '713: Last/Home Address/DOB(mm/dd/yyyy)'
      },
      {
        value: '714',
        label: '714: Last/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '715',
        label: '715: First/Last/Home city'
      },
      {
        value: '716',
        label: '716: First/Middle/Last'
      },
      {
        value: '717',
        label: '717: First/Middle/Last/Home city'
      },
      {
        value: '718',
        label: '718: First/Middle/Last/Home city/Home Zip'
      },
      {
        value: '719',
        label: '719: First/Middle/Last/Home city/Home State'
      },
      {
        value: '720',
        label: '720: First/Middle/Last/Home State'
      },
      {
        value: '721',
        label: '721: First/Middle/Last/Gender/Home Address/Home city/Home State/Home Zip/ID'
      },
      {
        value: '722',
        label: '722: First/Last/ID'
      },
      {
        value: '723',
        label: '723: First/Last/Phone'
      },
      {
        value: '724',
        label: '724: First/Last/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '725',
        label: '725: First/Last/Home Zip/Phone'
      },
      {
        value: '726',
        label: '726: First/Last/Phone4'
      },
      {
        value: '727',
        label: '727: First/Last/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '728',
        label: '728: First/Last/Home Zip/Phone4'
      },
      {
        value: '729',
        label: '729: First Initial/Last/DOB(mm/dd/yyyy)'
      },
      {
        value: '730',
        label: '730: First/Last/DOB(mm/dd/yyyy)'
      },
      {
        value: '731',
        label: '731: First/Last/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '732',
        label: '732: First/Last/Home Address/Home Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '733',
        label: '733: First/Last/Home Address/Home Zip/Phone/DOB(yyyy)'
      },
      {
        value: '734',
        label: '734: First/Last/Mail Address/Mail Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '735',
        label: '735: First/Last/Mail Address/Mail Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '736',
        label: '736: First/Last/Mail Address/Mail Zip/Phone/DOB(yyyy)'
      },
      {
        value: '737',
        label: '737: First Initial/Last/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '738',
        label: '738: First Initial/Last/Home Address/Home Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '739',
        label: '739: First Initial/Last/Home Address/Home Zip/Phone/DOB(yyyy)'
      },
      {
        value: '740',
        label: '740: First Initial/Last/Mail Address/Mail Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '741',
        label: '741: First Initial/Last/Mail Address/Mail Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '742',
        label: '742: First Initial/Last/Mail Address/Mail Zip/Phone/DOB(yyyy)'
      },
      {
        value: '743',
        label: '743: First/Last/Home Address/Home Zip/Phone'
      },
      {
        value: '744',
        label: '744: First/Last/Mail Address/Mail Zip/Phone'
      },
      {
        value: '745',
        label: '745: First Initial/Last/Home Address/Home Zip/Phone'
      },
      {
        value: '746',
        label: '746: First Initial/Last/Mail Address/Mail Zip/Phone'
      },
      {
        value: '747',
        label: '747: First/Last/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '748',
        label: '748: First/Last/Home Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '749',
        label: '749: First/Last/Home Zip/Phone/DOB(yyyy)'
      },
      {
        value: '750',
        label: '750: First/Last/Mail Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '751',
        label: '751: First/Last/Mail Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '752',
        label: '752: First/Last/Mail Zip/Phone/DOB(yyyy)'
      },
      {
        value: '753',
        label: '753: First Initial/Last/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '754',
        label: '754: First Initial/Last/Home Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '755',
        label: '755: First Initial/Last/Home Zip/Phone/DOB(yyyy)'
      },
      {
        value: '756',
        label: '756: First Initial/Last/Mail Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '757',
        label: '757: First Initial/Last/Mail Zip/Phone/DOB(mm/yyyy)'
      },
      {
        value: '758',
        label: '758: First Initial/Last/Mail Zip/Phone/DOB(yyyy)'
      },
      {
        value: '759',
        label: '759: First/Last/Home Address/Home Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '760',
        label: '760: First/Last/Home Address/Home Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '761',
        label: '761: First/Last/Home Address/Home Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '762',
        label: '762: First/Last/Mail Address/Mail Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '763',
        label: '763: First/Last/Mail Address/Mail Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '764',
        label: '764: First/Last/Mail Address/Mail Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '765',
        label: '765: First Initial/Last/Home Address/Home Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '766',
        label: '766: First Initial/Last/Home Address/Home Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '767',
        label: '767: First Initial/Last/Home Address/Home Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '768',
        label: '768: First Initial/Last/Mail Address/Mail Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '769',
        label: '769: First Initial/Last/Mail Address/Mail Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '770',
        label: '770: First Initial/Last/Mail Address/Mail Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '771',
        label: '771: First/Last/Home Address/Home Zip/Phone4'
      },
      {
        value: '772',
        label: '772: First/Last/Mail Address/Mail Zip/Phone4'
      },
      {
        value: '773',
        label: '773: First Initial/Last/Home Address/Home Zip/Phone4'
      },
      {
        value: '774',
        label: '774: First Initial/Last/Mail Address/Mail Zip/Phone4'
      },
      {
        value: '775',
        label: '775: First/Last/Home Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '776',
        label: '776: First/Last/Home Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '777',
        label: '777: First/Last/Home Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '778',
        label: '778: First/Last/Mail Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '779',
        label: '779: First/Last/Mail Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '780',
        label: '780: First/Last/Mail Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '781',
        label: '781: First Initial/Last/Home Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '782',
        label: '782: First Initial/Last/Home Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '783',
        label: '783: First Initial/Last/Home Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '784',
        label: '784: First Initial/Last/Mail Zip/Phone4/DOB(mm/dd/yyyy)'
      },
      {
        value: '785',
        label: '785: First Initial/Last/Mail Zip/Phone4/DOB(mm/yyyy)'
      },
      {
        value: '786',
        label: '786: First Initial/Last/Mail Zip/Phone4/DOB(yyyy)'
      },
      {
        value: '787',
        label: '787: Home State/Phone'
      },
      {
        value: '788',
        label: '788: Mail State/Phone'
      },
      {
        value: '789',
        label: '789: Phone'
      },
      {
        value: '790',
        label: '790: Last/Home State/Phone'
      },
      {
        value: '791',
        label: '791: Last/Mail State/Phone'
      },
      {
        value: '792',
        label: '792: Last/Home Address/Home Zip/Phone'
      },
      {
        value: '793',
        label: '793: Last/Home State/Phone4'
      },
      {
        value: '794',
        label: '794: Last/Mail State/Phone4'
      },
      {
        value: '796',
        label: '796: First Initial/Last/Home Address'
      },
      {
        value: '797',
        label: '797: Last/Home Address/Phone'
      },
      {
        value: '798',
        label: '798: Last/Home Address/Phone4'
      },
      {
        value: '799',
        label: '799: Last/Phone'
      },
      {
        value: '800',
        label: '800: First/Last/Home Zip/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '801',
        label: '801: First/Last/Home Zip/DOB(mm/yyyy)/SSN'
      },
      {
        value: '802',
        label: '802: First/Last/Home Zip/DOB(yyyy)/SSN'
      },
      {
        value: '803',
        label: '803: First/Last/Home Zip/SSN'
      },
      {
        value: '804',
        label: '804: First Initial/Last/Home State/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '805',
        label: '805: First Initial/Last/Home State/DOB(mm/yyyy)/SSN'
      },
      {
        value: '806',
        label: '806: Last/Home State/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '807',
        label: '807: First/Home State/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '808',
        label: '808: Last/Home Zip/SSN'
      },
      {
        value: '809',
        label: '809: Last/Phone/SSN'
      },
      {
        value: '810',
        label: '810: Last/SSN'
      },
      {
        value: '811',
        label: '811: First/Last/SSN'
      },
      {
        value: '812',
        label: '812: Last/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '813',
        label: '813: DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '814',
        label: '814: First/Last/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '815',
        label: '815: First/Last/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '816',
        label: '816: First/Last/Home Zip/DOB(yyyy)/SSN4'
      },
      {
        value: '817',
        label: '817: First/Last/Home Zip/SSN4'
      },
      {
        value: '818',
        label: '818: First Initial/Last/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '819',
        label: '819: First Initial/Last/Home State/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '820',
        label: '820: Last/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '821',
        label: '821: First/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '822',
        label: '822: Last/Home Zip/SSN4'
      },
      {
        value: '823',
        label: '823: Last/Phone/SSN4'
      },
      {
        value: '824',
        label: '824: Last/SSN4'
      },
      {
        value: '825',
        label: '825: First/Last/SSN4'
      },
      {
        value: '826',
        label: '826: Last/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '827',
        label: '827: DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '828',
        label: '828: Last/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '829',
        label: '829: First/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '830',
        label: '830: Last/Gender/Home State/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '831',
        label: '831: First/Gender/Home State/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '832',
        label: '832: First/Last/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '833',
        label: '833: First/Last/Home State/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '835',
        label: '835: Last/Home State/DOB(yyyy)/SSN'
      },
      {
        value: '836',
        label: '836: First Initial/Last/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '837',
        label: '837: First Initial/Last/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '838',
        label: '838: First/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '839',
        label: '839: First/Last/Home Address/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '840',
        label: '840: First/Last/Home Address/Home Zip/DOB(yyyy)/SSN4'
      },
      {
        value: '841',
        label: '841: First Initial/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '842',
        label: '842: First Initial/Last/Home Address/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '843',
        label: '843: First Initial/Last/Home Address/Home Zip/DOB(yyyy)/SSN4'
      },
      {
        value: '844',
        label: '844: Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '845',
        label: '845: Last/Home Address/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '846',
        label: '846: Last/Home Address/Home Zip/DOB(yyyy)/SSN4'
      },
      {
        value: '847',
        label: '847: First Initial/Last/SSN4'
      },
      {
        value: '848',
        label: '848: First/Last/Home Address/Home Zip/SSN4'
      },
      {
        value: '849',
        label: '849: First Initial/Last/Home Address/Home Zip/SSN4'
      },
      {
        value: '850',
        label: '850: Last/Home Address/Home Zip/SSN4'
      },
      {
        value: '851',
        label: '851: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '852',
        label: '852: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '853',
        label: '853: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(yyyy)/SSN4'
      },
      {
        value: '854',
        label: '854: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '855',
        label: '855: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '856',
        label: '856: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(yyyy)/SSN4'
      },
      {
        value: '865',
        label: '865: First Initial/Last/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '866',
        label: '866: First Initial/Last/Home Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '867',
        label: '867: First Initial/Last/Home Zip/DOB(yyyy)/ID'
      },
      {
        value: '868',
        label: '868: First Initial/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '869',
        label: '869: First Initial/Last/Home Address/Home Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '870',
        label: '870: First Initial/Last/Home Address/Home Zip/DOB(yyyy)/ID'
      },
      {
        value: '871',
        label: '871: Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '872',
        label: '872: First/Last/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '873',
        label: '873: First/Last/Home State/DOB(mm/yyyy)/ID'
      },
      {
        value: '874',
        label: '874: First/Last/Home State/DOB(yyyy)/ID'
      },
      {
        value: '875',
        label: '875: First Initial/Last/Home State/DOB(mm/yyyy)/ID'
      },
      {
        value: '876',
        label: '876: First Initial/Last/Home State/DOB(yyyy)/ID'
      },
      {
        value: '877',
        label: '877: First/Last/Home Address/Home Zip/ID'
      },
      {
        value: '878',
        label: '878: First Initial/Last/Home Address/Home Zip/ID'
      },
      {
        value: '879',
        label: '879: Last/Home Address/Home Zip/ID'
      },
      {
        value: '880',
        label: '880: First/Last/Home State/ID'
      },
      {
        value: '881',
        label: '881: First/Last/Mail State/ID'
      },
      {
        value: '882',
        label: '882: First Initial/Last/Home State/ID'
      },
      {
        value: '883',
        label: '883: First Initial/Last/Mail State/ID'
      },
      {
        value: '884',
        label: '884: Last/Home State/ID'
      },
      {
        value: '885',
        label: '885: Last/Mail State/ID'
      },
      {
        value: '887',
        label: '887: DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '890',
        label: '890: Mail State/ID'
      },
      {
        value: '895',
        label: '895: Home State/ID'
      },
      {
        value: '896',
        label: '896: ID#'
      },
      {
        value: '900',
        label: '900: ID Algorithm - DOB Verified'
      },
      {
        value: '905',
        label: '905: ID Algorithm - Format Verified'
      },
      {
        value: '1000',
        label: '1000: First/Last/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1001',
        label: '1001: First/Last/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1002',
        label: '1002: First Initial/Last/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '7',
        label: '7: First/Last/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '858',
        label: '858: First/Last/DOB(mm/dd/yyyy)/SSN'
      },
      {
        value: '86',
        label: '86: First/Last/Home Address/Home city/Home State/Home Zip'
      },
      {
        value: '88',
        label: '88: First Initial/Last/Home Address/Home city/Home State/Home Zip'
      },
      {
        value: '272',
        label: '272: First Initial/Last/Gender/Home Address/Home Zip'
      },
      {
        value: '273',
        label: '273: First/Last/Gender/Home Address/Home State'
      },
      {
        value: '274',
        label: '274: First Initial/Last/Gender/Home Address/Home State'
      },
      {
        value: '281',
        label: '281: First/Last/Gender/Home city/Home State'
      },
      {
        value: '282',
        label: '282: First Initial/Last/Gender/Home city/Home State'
      },
      {
        value: '331',
        label: '331: Last/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '337',
        label: '337: Last/Home Address/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '620',
        label: '620: First/Last/DOB(yyyy)'
      },
      {
        value: '645',
        label: '645: First/Last/Home Address/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '670',
        label: '670: First/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '675',
        label: '675: First/Last/Home Address/Home city/Home State/Home Zip/Phone'
      },
      {
        value: '680',
        label: '680: First/Last/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '857',
        label: '857: First/Last/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '886',
        label: '886: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '888',
        label: '888: Home Address/Home Zip/ID'
      },
      {
        value: '891',
        label: '891: First Initial/Last/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '892',
        label: '892: First Initial/Last/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '893',
        label: '893: First Initial/Last/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '894',
        label: '894: First Initial/Last/Home Zip/DOB(yyyy)/SSN4'
      },
      {
        value: '897',
        label: '897: DOB(mm/dd/yyyy)'
      },
      {
        value: '898',
        label: '898: First Initial/Last/Home State/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1003',
        label: '1003: Last/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1025',
        label: '1025: First/Last/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1030',
        label: '1030: First/Last/Home Address/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1035',
        label: '1035: First/Last/Home city/Home State/Home Zip/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1040',
        label: '1040: First/Last/Home Zip/Phone/ID'
      },
      {
        value: '1045',
        label: '1045: First/Last/Home city/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1050',
        label: '1050: First/Last/Home city/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1055',
        label: '1055: First/Last/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1060',
        label: '1060: First/Last/Home Address/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1065',
        label: '1065: First/Last/Home Address/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1070',
        label: '1070: First/Last/Home city/Home State/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1075',
        label: '1075: First/Last/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1080',
        label: '1080: First/Last/Home city/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1085',
        label: '1085: First/Last/Phone/ID'
      },
      {
        value: '1090',
        label: '1090: Last/Home Address/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1095',
        label: '1095: Last/Home city/Home State/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '521',
        label: '521: First/Last/Home Address/Home city/DOB(yyyy)'
      },
      {
        value: '522',
        label: '522: First Initial/Last/Home Address/Home city/DOB(yyyy)'
      },
      {
        value: '523',
        label: '523: First/Last/Home city/DOB(yyyy)'
      },
      {
        value: '524',
        label: '524: First Initial/Last/Home city/DOB(yyyy)'
      },
      {
        value: '8',
        label: '8: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '9',
        label: '9: First Initial/Last/Home Address/Home city/Home State/Home\n    Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '61',
        label: '61: First/Last/Gender/Home Address/Home city/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '62',
        label: '62: First/Last/Gender/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '63',
        label: '63: First/Last/Home Address/Home city/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '201',
        label: '201: First Initial/Last/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '283',
        label: '283: First/Last/Gender/Home Address/Home city/Home State'
      },
      {
        value: '316',
        label: '316: Last/DOB(mm/dd/yyyy)'
      },
      {
        value: '626',
        label: '626: First Initial/Last/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '631',
        label: '631: First/Last/Home Address/Home city/DOB(mm/dd/yyyy)'
      },
      {
        value: '632',
        label: '632: First Initial/Last/Home Address/Home city/DOB(mm/dd/yyyy)'
      },
      {
        value: '633',
        label: '633: First Initial/Last/Home city/DOB(mm/dd/yyyy)'
      },
      {
        value: '634',
        label: '634: First/Last/Home Address/Home city/DOB(yyyy)'
      },
      {
        value: '635',
        label: '635: First Initial/Last/Home Address/Home city/DOB(yyyy)'
      },
      {
        value: '636',
        label: '636: First/Last/Home city/DOB(yyyy)'
      },
      {
        value: '637',
        label: '637: First Initial/Last/Home city/DOB(yyyy)'
      },
      {
        value: '646',
        label: '646: First/Last/Home Address/Home city/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '647',
        label: '647: First/Last/Gender/Home Address/Home city/Home State/DOB(mm/dd/yyyy)'
      },
      {
        value: '660',
        label: '660: First/Last/Home Address/Home city/Home State/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '661',
        label: '661: First/Last/Gender/Home Address/Home city/Home\n    State/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '673',
        label: '673: First/Last/Gender/Home Address/Home Zip/Phone'
      },
      {
        value: '674',
        label: '674: First/Last/Gender/Home Address/Home city/Home State/Phone'
      },
      {
        value: '676',
        label: '676: First/Last/Home Address/Home city/Home State/Phone'
      },
      {
        value: '834',
        label: '834: First/Last/Home Address/Home city/Home State/SSN4'
      },
      {
        value: '889',
        label: '889: Home Address/Home city/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '899',
        label: '899: Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '910',
        label: '910: First/Last/Home Address/Home City/Home State/Home Zip/ID'
      },
      {
        value: '911',
        label: '911: First/Last/Home Address/Home City/Home State/Home Zip/SSN4'
      },
      {
        value: '915',
        label: '915: Home Address/Home City/Home State/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '916',
        label: '916: Home Address/Home City/Home State/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '920',
        label: '920: Home Address/Home City/Home State/Home Zip/ID'
      },
      {
        value: '921',
        label: '921: Home Address/Home City/Home State/Home Zip/SSN4'
      },
      {
        value: '925',
        label: '925: Home Address/Home City/Home State/Home Zip/DOB(mm/dd/yyyy)'
      },
      {
        value: '930',
        label: '930: Home Address/Home City/Home State/Home Zip'
      },
      {
        value: '1004',
        label: '1004: First/Home Address/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1005',
        label: '1005: First/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1006',
        label: '1006: Last/Home Zip/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1007',
        label: '1007: First/Last/Home Address/Home city/Home State/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1008',
        label: '1008: First/Home Address/Home city/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1009',
        label: '1009: First/Last/Home Address/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1010',
        label: '1010: First/Last/Home Address/Home city/Home State/ID'
      },
      {
        value: '1011',
        label: '1011: First/Last/Home Address/Home city/Home State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1012',
        label: '1012: First/Home Address/Home State/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1013',
        label: '1013: First/Last/Gender/Home Address/Home city/Home\n    State/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1014',
        label: '1014: First/Gender/Home Address/Home State/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1015',
        label: '1015: First/Last/Home Address/Home city/Home State/Phone/ID'
      },
      {
        value: '1016',
        label: '1016: First/Last/Gender/Home Address/Home Zip/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1017',
        label: '1017: First/Last/Gender/Home Address/Home city/Home\n    State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1018',
        label: '1018: First/Last/Gender/Home Address/Home city/Home State/Phone/ID'
      },
      {
        value: '1019',
        label: '1019: First/Last/Gender/Home Address/Home Zip/Phone/ID'
      },
      {
        value: '1020',
        label: '1020: First/Last/Home Address/Home Zip/Phone/ID'
      },
      {
        value: '1024',
        label: '1024: First/Last/Gender/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)/ID'
      },
      {
        value: '1026',
        label: '1026: First/Last/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1027',
        label: '1027: First/Last/Gender/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1036',
        label: '1036: First/Last/Gender/Home Address/Home Zip/Phone/DOB(mm/dd/yyyy)'
      },
      {
        value: '1078',
        label: '1078: First/Last/Gender/Home Address/Home city/Home State/SSN4'
      },
      {
        value: '1079',
        label: '1079: First/Last/Gender/Home Address/Home city/Home State/ID'
      },
      {
        value: '1105',
        label: '1105: Last/Home Zip/DOB(mm/yyyy)/ID'
      },
      {
        value: '1110',
        label: '1110: Last/Home Zip/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1118',
        label: '1118: First/Last/Gender/Home Address/Home Zip/Phone/SSN4'
      },
      {
        value: '1119',
        label: '1119: First/Last/Gender/Home Address/Home Zip/SSN4'
      },
      {
        value: '1120',
        label: '1120: First/Last/Home Address/Home Zip/Phone/SSN4'
      },
      {
        value: '1124',
        label: '1124: First/Last/Gender/Home Address/Home city/Home State/Phone/SSN4'
      },
      {
        value: '1125',
        label: '1125: First/Last/Home Address/Home city/Home State/Phone/SSN4'
      },
      {
        value: '1145',
        label: '1145: First/Last/Home Address/Home city/Home State/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1150',
        label: '1150: First/Last/Home Address/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1151',
        label: '1151: First/Last/Home Address/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1152',
        label: '1152: First/Last/Home Address/DOB(yyyy)/SSN4'
      },
      {
        value: '1153',
        label: '1153: First Initial/Last/Home Address/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1154',
        label: '1154: First Initial/Last/Home Address/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1155',
        label: '1155: First Initial/Last/Home Address/DOB(yyyy)/SSN4'
      },
      {
        value: '1156',
        label: '1156: Last/Home Address/DOB(mm/dd/yyyy)/SSN4'
      },
      {
        value: '1157',
        label: '1157: Last/Home Address/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1158',
        label: '1158: Last/DOB(mm/yyyy)/SSN4'
      },
      {
        value: '1159',
        label: '1159: Last/DOB(yyyy)/SSN4'
      },
      {
        value: '1160',
        label: '1160: First/Last/Home Address/Home city/ID'
      },
      {
        value: '8001',
        label: '8001: >=36 month credit, 1-2 sources'
      },
      {
        value: '8002',
        label: '8002: <=6 month credit, 2 sources'
      },
      {
        value: '8003',
        label: '8003: <=6 month credit'
      }
    ].sort((a, b) => parseInt(a.value) - parseInt(b.value))
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_primary_result_message',
    label: 'Primary Result Message',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_ssn_administration_fraud_list',
    label: 'SSN Administration Fraud List',
    type: 'single',
    option: [
      {
        value: 'Y',
        label: 'True'
      },
      {
        value: 'N',
        label: 'False'
      }
    ]
  },
  {
    value: 'ssn_first_five',
    label: 'SSN First 5',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_vendor_transaction_id',
    label: 'Aristotle Transaction ID',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--aristotle--consumer_intelligence_verify_number_on_government_issued_id',
    label: 'Verify Number on Government Issued ID',
    type: 'single'
  },
  {
    label: 'Overall Result',
    value: 'consumer_intelligence--aristotle--overall_result',
    type: 'single'
  },
  {
    label: 'Reason for Failure (if available)',
    value: 'consumer_intelligence--aristotle--reason_for_failure',
    type: 'single'
  },
  {
    label: 'Age Check Result',
    value: 'consumer_intelligence--aristotle--age_check_result',
    type: 'multiple',
    option: [
      {
        value: 'Passed',
        label: 'Passed'
      },
      {
        value: 'Failed',
        label: 'Failed'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    label: 'Name Check Result',
    value: 'consumer_intelligence--aristotle--name_check_result',
    type: 'single'
  },
  {
    label: 'SSN/TIN Check Result',
    value: 'consumer_intelligence--aristotle--check_result_result',
    type: 'single'
  },
  {
    label: 'Address Check Result',
    value: 'consumer_intelligence--aristotle--address_check_eesult',
    type: 'single'
  },
  {
    label: 'Aristotle Transaction Request Date/Time Stamp',
    value: 'consumer_intelligence--aristotle--aristotle_transaction_request_stamp',
    type: 'single'
  },
  {
    label: 'Aristotle Transaction Response Date/Time Stamp',
    value: 'consumer_intelligence--aristotle--aristotle_transaction_response_stamp',
    type: 'single'
  }
];

export const idologyOptions: Option[] = [
  {
    label: 'IDology Transaction ID',
    value: 'consumer_intelligence--idology--iDology_transaction_id',
    type: 'single'
  },
  {
    label: 'Overall Result',
    value: 'consumer_intelligence--idology--overall_result',
    type: 'single'
  },
  {
    label: 'Name Check Result',
    value: 'consumer_intelligence--idology--name_check_result',
    type: 'single'
  },
  {
    label: 'SSN/TIN Check Result',
    value: 'consumer_intelligence--idology--check_result_result',
    type: 'single'
  },
  {
    label: 'Address Check Result',
    value: 'consumer_intelligence--idology--address_check_eesult',
    type: 'single'
  },
  {
    label: 'IDology Transaction Request Date/Time Stamp',
    value: 'consumer_intelligence--idology--idology_transaction_request_stamp',
    type: 'single'
  },
  {
    label: 'IDology Transaction Response Date/Time Stamp',
    value: 'consumer_intelligence--idology--idology_transaction_response_stamp',
    type: 'single'
  },
  {
    label: 'Restriction - Global Watch List',
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_global_watch_list',
    type: 'single'
  },
  {
    label: 'Deceased',
    value: 'consumer_intelligence--idology--consumer_intelligence_deceased',
    type: 'single'
  },
  {
    label: 'Located SSN Is ITIN',
    value: 'consumer_intelligence--idology--consumer_intelligence_located_ssn_is_itin',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    label: 'PO Box',
    value: 'consumer_intelligence--idology--consumer_intelligence_po_box',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    label: 'Age Check Result',
    value: 'consumer_intelligence--idology--consumer_intelligence_age_check_result',
    type: 'multiple',
    option: [
      {
        value: 'Passed',
        label: 'Passed'
      },
      {
        value: 'Failed',
        label: 'Failed'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    label: 'Alert - Blacklist Address',
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_address',
    type: 'single'
  },
  {
    label: 'Invalid SSN',
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_ssn',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    label: 'SSN Issued Prior to Date of Birth',
    value: 'consumer_intelligence--idology--consumer_intelligence_ssn_issued_prior_to_dob',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    label: 'SSN Is ITIN',
    value: 'consumer_intelligence--idology--consumer_intelligence_ssn_is_itin',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_dob',
    label: 'Unavailable - Date of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_ssn_within_1_digit',
    label: 'SSN within 1 Digit',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_mob',
    label: 'Unavailable - Month of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_ssn',
    label: 'Unavailable - SSN',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_ssn',
    label: 'Unmatch - SSN',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_aob',
    label: 'Unmatch - Day of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_dob',
    label: 'Unmatch - Date of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_last_name',
    label: 'Unmatch - Last Name',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_mob',
    label: 'Unmatch - Month of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_state',
    label: 'Unmatch - State',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_street_name',
    label: 'Unmatch - Street Name',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_street_number',
    label: 'Unmatch - Street Number',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_yob',
    label: 'Unmatch - Year of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_zip',
    label: 'Unmatch - Zip',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_yob_within_1_year',
    label: 'Year of Birth within 1 Year',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_age_above_maximum',
    label: 'Age above Max',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_age_below_minimum',
    label: 'Age below Min',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_activation_date',
    label: 'Alert - Activation Date',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_address_location',
    label: 'Alert - Address Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_address_longevity',
    label: 'Alert - Address Longevity',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_address_stability',
    label: 'Alert - Address Stability',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_address_velocity',
    label: 'Alert - Address Velocity',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_address_on_network_list',
    label: 'Alert - Address on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_alternate_address',
    label: 'Alert - Alternate Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_email',
    label: 'Alert - Blacklist E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_email_domain',
    label: 'Alert - Blacklist E-mail Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_ip_address',
    label: 'Alert - Blacklist IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_mac_address',
    label: 'Alert - Blacklist MAC Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_phone_number',
    label: 'Alert - Blacklist Phone Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_blacklist_ssn',
    label: 'Alert - Blacklist SSN',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_coppa',
    label: 'Alert - COPPA',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_cell_phone_account_age',
    label: 'Alert - Cell Phone Account Age',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_data_strength',
    label: 'Alert - Data Strength',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_domain_on_network_list',
    label: 'Alert - Domain on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_email_on_network_list',
    label: 'Alert - E-mail on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_high_risk_address',
    label: 'Alert - High Risk Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_ip_address_on_network_list',
    label: 'Alert - IP Address on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_ip_location',
    label: 'Alert - IP Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_mac_address_on_network_list',
    label: 'Alert - MAC Address on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_phone_number_on_network_list',
    label: 'Alert - Phone Number on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_ssn_on_network_list',
    label: 'Alert - SSN on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_warm_address',
    label: 'Alert - Warm Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_activation_date',
    label: 'Alert Message - Activation Date',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_address_location',
    label: 'Alert Message - Address Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_address_longevity',
    label: 'Alert Message - Address Longevity',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_address_stability',
    label: 'Alert Message - Address Stability',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_address_velocity',
    label: 'Alert Message - Address Velocity',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_address_on_network_list',
    label: 'Alert Message - Address on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_alternate_address',
    label: 'Alert Message - Alternate Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_address',
    label: 'Alert Message - Blacklist Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_email',
    label: 'Alert Message - Blacklist E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_email_domain',
    label: 'Alert Message - Blacklist E-mail Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_ip_address',
    label: 'Alert Message - Blacklist IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_mac_address',
    label: 'Alert Message - Blacklist MAC Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_phone_number',
    label: 'Alert Message - Blacklist Phone Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_blacklist_ssn',
    label: 'Alert Message - Blacklist SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_coppa',
    label: 'Alert Message - COPPA',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_cell_phone_account_age',
    label: 'Alert Message - Cell Phone Account Age',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_data_strength',
    label: 'Alert Message - Data Strength',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_domain_on_network_list',
    label: 'Alert Message - Domain on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_email_on_network_list',
    label: 'Alert Message - E-mail on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_high_risk_address',
    label: 'Alert Message - High Risk Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_ip_address_on_network_list',
    type: 'single',
    label: 'Alert Message - IP Address on Network List'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_ip_location',
    label: 'Alert Message - IP Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_mac_address_on_network_list',
    label: 'Alert Message - MAC Address on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_phone_number_on_network_list',
    label: 'Alert Message - Phone Number on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_ssn_on_network_list',
    label: 'Alert Message - SSN on Network List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_alert_message_warm_address',
    label: 'Alert Message - Warm Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_bankruptcy',
    label: 'Bankruptcy',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_change_device',
    label: 'Cell Phone Account Change - Device',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_change_network_status',
    label: 'Cell Phone Account Change - Network Status',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_change_number',
    label: 'Cell Phone Account Change - Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_change_ported',
    label: 'Cell Phone Account Change - Ported',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_change_sim_swap',
    label: 'Cell Phone Account Change - Sim Swap',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_status_absent',
    label: 'Cell Phone Account Status - Absent',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_status_active',
    label: 'Cell Phone Account Status - Active',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_status_inactive',
    label: 'Cell Phone Account Status - Inactive',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_status_suspended',
    label: 'Cell Phone Account Status - Suspended',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_type_hybrid',
    label: 'Cell Phone Account Type - Hybrid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_type_postpaid',
    label: 'Cell Phone Account Type - Postpaid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_type_prepaid',
    label: 'Cell Phone Account Type - Prepaid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_cell_phone_account_type_unknown',
    label: 'Cell Phone Account Type - Unknown',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_eligible_for_questions',
    label: 'Eligible for Questions',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_found_multiple_records',
    label: 'Found Multiple Records',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_found_newer_record',
    label: 'Found Newer Record',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_email_country',
    label: 'High Risk E-mail Country',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_email_domain',
    label: 'High Risk E-mail Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_email_fraud',
    label: 'High Risk E-mail Fraud',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_email_recently_verified',
    label: 'High Risk E-mail Recently Verified',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_ip_address_botnet',
    label: 'High Risk IP Address Botnet',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_ip_address_malware',
    label: 'High Risk IP Address Malware',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_high_risk_ip_address_tor',
    label: 'High Risk IP Address Tor',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_risk_high',
    label: 'High Risk Score',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_id_scan_url',
    label: 'ID Scan URL',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_inquiry_summary_result',
    label: 'Inquiry Summary Result',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_ip_address',
    label: 'Invalid - IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_email_address',
    label: 'Invalid E-mail Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_message_email_address',
    label: 'Invalid Message - E-mail Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_message_ip_address',
    label: 'Invalid Message - IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_invalid_message_ssn',
    label: 'Invalid Message - SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_live_questions_indicated',
    label: 'Live Questions Indicated',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_located_dob_month',
    label: 'Located Date of Birth - Month',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_located_dob_year',
    label: 'Located Date of Birth - Year',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_located_po_box',
    label: 'Located PO Box',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_located_phpne_carrier',
    label: 'Located Phone Carrier',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_located_phpne_line_type',
    label: 'Located Phone Line Type',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_risk_low',
    label: 'Low Risk Score',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_risk_medium',
    label: 'Medium Risk Score',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_age_check_result_message',
    label: 'Message - Age Check Result',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_age_above_maximum',
    label: 'Message - Age above Max',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_age_below_minimum',
    label: 'Message - Age below Min',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_bankruptcy',
    label: 'Message - Bankruptcy',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_change_device',
    label: 'Message - Cell Phone Account Change - Device',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_change_network_status',
    label: 'Message - Cell Phone Account Change - Network Status',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_change_number',
    label: 'Message - Cell Phone Account Change - Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_change_ported',
    label: 'Message - Cell Phone Account Change - Ported',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_change_sim_swap',
    label: 'Message - Cell Phone Account Change - Sim Swap',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_status_absent',
    label: 'Message - Cell Phone Account Status - Absent',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_status_active',
    label: 'Message - Cell Phone Account Status - Active',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_status_inactive',
    label: 'Message - Cell Phone Account Status - Inactive',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_status_suspended',
    label: 'Message - Cell Phone Account Status - Suspended',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_type_hybrid',
    label: 'Message - Cell Phone Account Type - Hybrid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_type_postpaid',
    label: 'Message - Cell Phone Account Type - Postpaid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_type_prepaid',
    label: 'Message - Cell Phone Account Type - Prepaid',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_cell_phone_account_type_unknown',
    label: 'Message - Cell Phone Account Type - Unknown',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_deceased',
    label: 'Message - Deceased',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_found_multiple_records',
    label: 'Message - Found Multiple Records',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_found_newer_record',
    label: 'Message - Found Newer Record',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_email_country',
    label: 'Message - High Risk E-mail Country',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_email_domain',
    label: 'Message - High Risk E-mail Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_email_fraud',
    label: 'Message - High Risk E-mail Fraud',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_email_recently_verified',
    label: 'Message - High Risk E-mail Recently Verified',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_ip_address_botnet',
    label: 'Message - High Risk IP Address Botnet',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_ip_address_malware',
    label: 'Message - High Risk IP Address Malware',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_high_risk_ip_address_tor',
    label: 'Message - High Risk IP Address Tor',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_located_po_box',
    label: 'Message - Located PO Box',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_located_ssn_is_itin',
    label: 'Message - Located SSN Is ITIN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_no_hit_risk_flag_email',
    label: 'Message - No Hit - Risk Flag - E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_po_box',
    label: 'Message - PO Box',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_match_message_dob',
    label: 'Message - Patriot Act List - Match - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_unavailable_message_dob',
    label: 'Message - Patriot Act List - Unavailable - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_unmatch_message_dob',
    label: 'Message - Patriot Act List - Unmatch - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_match_result_phone',
    label: 'Message - Phone Match Result',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_restricted_ip_country',
    label: 'Message - Restricted IP Country',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_restrictedip_proxy',
    label: 'Message - Restricted IP Proxy',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_ssn_is_itin',
    label: 'Message - SSN Is ITIN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_ssn_issued_prior_to_dob',
    label: 'Message - SSN Issued Prior to Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_ssn_within_1_digit',
    label: 'Message - SSN within 1 Digit',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_single_address_in_file',
    label: 'Message - Single Address in File',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_thin_file',
    label: 'Message - Thin File',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_undeliverable_input_address',
    label: 'Message - Undeliverable - Input Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_undeliverable_located_address',
    label: 'Message - Undeliverable - Located Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_unlocated_ip_address',
    label: 'Message - Unlocated IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_yob_within_1_year',
    label: 'Message - Year of Birth within 1 Year',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_multiple_matches_ssn',
    label: 'Multiple Matches - SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_multiple_matches_message_ssn',
    label: 'Multiple Matches Message - SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_city',
    label: 'Newer Record - City',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_first_name',
    label: 'Newer Record - First Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_last_name',
    label: 'Newer Record - Last Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_last_seen_month',
    label: 'Newer Record - Last Seen Month',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_last_seen_year',
    label: 'Newer Record - Last Seen Year',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_middle_name',
    label: 'Newer Record - Middle Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_state',
    label: 'Newer Record - State',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_street_name',
    label: 'Newer Record - Street Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_street_number',
    label: 'Newer Record - Street Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_street_post_direction',
    label: 'Newer Record - Street Post Direction',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_street_pre_direction',
    label: 'Newer Record - Street Pre-Direction',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_street_suffix',
    label: 'Newer Record - Street Suffix',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_unit_designation',
    label: 'Newer Record - Unit Designation',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_unit_number',
    label: 'Newer Record - Unit Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_zip',
    label: 'Newer Record - Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_newer_record_zip_plus4',
    label: 'Newer Record - Zip+4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_no_hit_risk_flag_email',
    label: 'No Hit - Risk Flag - E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list',
    label: 'Patriot Act List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_foreign_sanctions_evaders',
    label: 'Patriot Act List - Foreign Sanctions Evader',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_match_dob',
    label: 'Patriot Act List - Match - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_office_of_foreign_asset_control',
    label: 'Patriot Act List - Office of Foreign Asset Control',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_palestinian_legislative_council',
    label: 'Patriot Act List - Palestinian Legislative Council',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_politically_exposed_persons',
    label: 'Patriot Act List - Politically Exposed Persons',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_unavailable_dob',
    label: 'Patriot Act List - Unavailable - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_unmatch_dob',
    label: 'Patriot Act List - Unmatch - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_score_foreign_sanctions_evaders',
    label: 'Patriot Act List Score - Foreign Sanctions Evader',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_score_office_of_foreign_asset_control',
    label: 'Patriot Act List Score - Office of Foreign Asset Control',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_score_palestinian_legislative_council',
    label: 'Patriot Act List Score - Palestinian Legislative Council',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_list_score_politically_exposed_persons',
    label: 'Patriot Act List Score - Politically Exposed Persons',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_pa_score',
    label: 'Patriot Act Score',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_phone_verification_residential_matches',
    label: 'Phone Verification Residential Matches',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_primary_result',
    label: 'Primary Result',
    type: 'multiple',
    option: [
      {
        value: 'Passed',
        label: 'Passed'
      },
      {
        value: 'Failed',
        label: 'Failed'
      },
      {
        value: 'Partial',
        label: 'Partial'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_primary_result_message',
    label: 'Primary Result Message',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_proxy_ip_address_type',
    label: 'Proxy IP Address Type',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restricted_ip_country',
    label: 'Restricted IP Country',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restricted_ip_proxy',
    label: 'Restricted IP Proxy',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_sku',
    label: 'Restriction - SKU',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_zip',
    label: 'Restriction - Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_message_global_watch_list',
    label: 'Restriction Message - Global Watch List',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_message_sku',
    label: 'Restriction Message - SKU',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_restriction_message_zip',
    label: 'Restriction Message - Zip',
    type: 'single'
  },
  // @TODO - figure out how to handle Idology result status (int/string issue)
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_result_status',
    label: 'Result Status',
    type: 'multiple',
    option: [
      {
        value: 100,
        label: 'Success'
      },
      {
        value: 901,
        label: 'Failed: General Error'
      },
      {
        value: 905,
        label: 'Failed: Temporarily Unavailable'
      },
      {
        value: 906,
        label: 'Failed: Access'
      },
      {
        value: 907,
        label: 'Failed: Verification'
      },
      {
        value: 908,
        label: 'Failed: Internal Error'
      },
      {
        value: 910,
        label: 'Failed: Incomplete'
      },
      {
        value: 911,
        label: 'Failed: Duplicate Entities of Same Type'
      },
      {
        value: 912,
        label: 'Failed: Invalid Parameter'
      },
      {
        value: 920,
        label: 'Failed: Unknown Session'
      },
      {
        value: 921,
        label: 'Failed: Invalid SHA Hash'
      },
      {
        value: 922,
        label: 'Failed: Invalid E-mail Address'
      },
      {
        value: 923,
        label: 'Failed: Invalid Telephone Number'
      },
      {
        value: 924,
        label: 'Failed: Invalid Device ID'
      },
      {
        value: 925,
        label: 'Failed: Invalid IP Address Parameter'
      },
      {
        value: 926,
        label: 'Failed: Invalid Account Number'
      },
      {
        value: 927,
        label: 'Failed: Missing or Invalid City'
      },
      {
        value: 928,
        label: 'Failed: Missing or Invalid Postal Code'
      },
      {
        value: 929,
        label: 'Failed: Missing or Invalid Country Code'
      },
      {
        value: 930,
        label: 'Failed: Missing or Invalid Currency Code'
      },
      {
        value: 931,
        label: 'Failed: Missing or Invalid Amount'
      },
      {
        value: 932,
        label: 'Failed: Missing, Invalid or Mismatched Payment Type'
      },
      {
        value: 933,
        label: 'Failed: Missing or Invalid Merchant Acknowledgment'
      },
      {
        value: 934,
        label: 'Failed: Missing POST'
      },
      {
        value: 935,
        label: 'Failed: Invalid Shopping Cart'
      },
      {
        value: 936,
        label: 'Failed: Missing or Invalid Product Type'
      },
      {
        value: 937,
        label: 'Failed: Missing or Invalid Product Item'
      },
      {
        value: 938,
        label: 'Failed: Missing or Invalid Product Description'
      },
      {
        value: 939,
        label: 'Failed: Missing or Invalid Product Quantity'
      },
      {
        value: 940,
        label: 'Failed: Missing or Invalid Product Price'
      },
      {
        value: 913,
        label: 'Failed: Consumer Not Found'
      },
      {
        value: 914,
        label: 'Failed: Security Alert'
      },
      {
        value: 941,
        label: 'Failed: Missing or Invalid SSN'
      },
      {
        value: 942,
        label: "Failed: Missing or Invalid Driver's License Number or State"
      },
      {
        value: 943,
        label: 'Failed: Missing or Invalid Date of Birth'
      },
      {
        value: 960,
        label: 'Failed: Registering Mobile Device'
      },
      {
        value: 961,
        label: 'Failed: Locating Mobile Device'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_risk_score',
    label: 'Risk Score',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_ssn',
    label: 'SSN First 5',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_should_scan_id',
    label: 'Should Scan ID',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_single_address_in_file',
    label: 'Single Address in File',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_thin_file',
    label: 'Thin File',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_aob',
    label: 'Unavailable - Day of Birth',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_email_service',
    label: 'Unavailable - E-mail Service',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_ip_location',
    label: 'Unavailable - IP Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_dob',
    label: 'Unavailable Message - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_aob',
    label: 'Unavailable Message - Day of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_email_service',
    label: 'Unavailable Message - E-mail Service',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_ip_location',
    label: 'Unavailable Message - IP Location',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_mob',
    label: 'Unavailable Message - Month of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unavailable_message_ssn',
    label: 'Unavailable Message - SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_undeliverable_input_address',
    label: 'Undeliverable - Input Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_undeliverable_located_address',
    label: 'Undeliverable - Located Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unique_person_id',
    label: 'Unique Person ID',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unlocated_ip_address',
    label: 'Unlocated IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_address',
    label: 'Unmatch - Address',
    type: 'multiple',
    option: [
      {
        label: 'True',
        value: 'true'
      },
      {
        label: 'False',
        value: 'false'
      }
    ]
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_email_or_domain',
    label: 'Unmatch - E-mail/Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_state_ip',
    label: 'Unmatch - State and IP',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_address',
    label: 'Unmatch Message - Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_dob',
    label: 'Unmatch Message - Date of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_aob',
    label: 'Unmatch Message - Day of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_email_or_domain',
    label: 'Unmatch Message - E-mail/Domain',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_last_name',
    label: 'Unmatch Message - Last Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_mob',
    label: 'Unmatch Message - Month of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_ssn',
    label: 'Unmatch Message - SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_state',
    label: 'Unmatch Message - State',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_message_unmatch_state_ip',
    label: 'Unmatch Message - State and IP',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_street_name',
    label: 'Unmatch Message - Street Name',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_street_number',
    label: 'Unmatch Message - Street Number',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_yob',
    label: 'Unmatch Message - Year of Birth',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_unmatch_message_zip',
    label: 'Unmatch Message - Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_alternate_street',
    label: 'Velocity - Alternate Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_email',
    label: 'Velocity - Input E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_fname_lname',
    label: 'Velocity - Input First & Last Names',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_ip_address',
    label: 'Velocity - Input IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_lname_ssn4',
    label: 'Velocity - Input Last Name & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_lname_street',
    label: 'Velocity - Input Last Name & Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_lname_street_ssn4',
    label: 'Velocity - Input Last Name & Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_phone',
    label: 'Velocity - Input Phone',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_ssn',
    label: 'Velocity - Input SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_street',
    label: 'Velocity - Input Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_street_ssn4',
    label: 'Velocity - Input Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_input_street_zip',
    label: 'Velocity - Input Street & Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_fname_lname',
    label: 'Velocity - Located First & Last Names',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_lname_ssn4',
    label: 'Velocity - Located Last Name & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_lname_street',
    label: 'Velocity - Located Last Name & Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_lname_street_ssn4',
    label: 'Velocity - Located Last Name & Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_phone',
    label: 'Velocity - Located Phone',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_ssn',
    label: 'Velocity - Located SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_street',
    label: 'Velocity - Located Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_street_ssn4',
    label: 'Velocity - Located Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_located_street_zip',
    label: 'Velocity - Located Street & Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_mac_address',
    label: 'Velocity - MAC Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_alternate_street',
    label: 'Velocity Message - Alternate Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_email',
    label: 'Velocity Message - Input E-mail',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_fname_lname',
    label: 'Velocity Message - Input First & Last Names',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_ip_address',
    label: 'Velocity Message - Input IP Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_lname_ssn4',
    label: 'Velocity Message - Input Last Name & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_lname_street',
    label: 'Velocity Message - Input Last Name & Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_lname_street_ssn4',
    label: 'Velocity Message - Input Last Name & Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_phone',
    label: 'Velocity Message - Input Phone',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_ssn',
    label: 'Velocity Message - Input SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_street',
    label: 'Velocity Message - Input Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_street_ssn4',
    label: 'Velocity Message - Input Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_input_street_zip',
    label: 'Velocity Message - Input Street & Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_fname_lname',
    label: 'Velocity Message - Located First & Last Names',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_lname_ssn4',
    label: 'Velocity Message - Located Last Name & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_lname_street',
    label: 'Velocity Message - Located Last Name & Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_lname_street_ssn4',
    label: 'Velocity Message - Located Last Name & Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_phone',
    label: 'Velocity Message - Located Phone',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_ssn',
    label: 'Velocity Message - Located SSN',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_street',
    label: 'Velocity Message - Located Street',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_street_ssn4',
    label: 'Velocity Message - Located Street & SSN4',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_located_street_zip',
    label: 'Velocity Message - Located Street & Zip',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_velocity_message_mac_address',
    label: 'Velocity Message - MAC Address',
    type: 'single'
  },
  {
    value: 'consumer_intelligence--idology--consumer_intelligence_vendor_transaction_id',
    label: 'Vendor Transaction ID',
    type: 'single'
  }
];

export const authenticOptions: Option[] = [
  {
    label: 'Action Code',
    value: 'consumer_intelligence--aid--consumer_intelligence_action_code',
    type: 'multiple',
    option: [
      {
        label: 'UnSupported ID(50)',
        value: '50'
      },
      {
        label: 'Bad ID & Selfie Capture(10)',
        value: '10'
      },
      {
        label: 'Bad ID Capture(12)',
        value: '12'
      },
      {
        label: 'Bad Selfie Capture(20)',
        value: '20'
      },
      {
        label: 'Expired ID(52)',
        value: '52'
      },
      {
        label: 'Fraud(60)',
        value: '60'
      },
      {
        label: 'Verified(99)',
        value: '99'
      }
    ]
  },
  {
    label: 'Pobox Result',
    value: 'consumer_intelligence--aid--consumer_intelligence_pobox_result',
    type: 'multiple',
    option: [
      {
        label: 'False',
        value: 'NO'
      },
      {
        label: 'True',
        value: 'YES'
      },
      {
        label: 'NA',
        value: 'NA'
      }
    ]
  },
  {
    label: 'PII Source Result',
    value: 'consumer_intelligence--aid--consumer_intelligence_pii_source_result',
    type: 'multiple',
    option: [
      {
        label: 'NA',
        value: 'NA'
      },
      {
        label: '2D',
        value: '2D'
      },
      {
        label: 'MRZ',
        value: 'MRZ'
      },
      {
        label: 'OCR',
        value: 'OCR'
      }
    ]
  }
  // {
  //   label: 'AuthID Transaction ID',
  //   value: 'consumer_intelligence--authid--authid_transaction_id',
  //   type: 'single'
  // },
  // {
  //   label: 'Overall Result',
  //   value: 'consumer_intelligence--authid--overall_result',
  //   type: 'single'
  // },
  // {
  //   label: 'Reason for Failure (if available)',
  //   value: 'consumer_intelligence--authid--reason_for_failure',
  //   type: 'single'
  // },
  // {
  //   label: 'Age Check Result',
  //   value: 'consumer_intelligence--authid--age_check_result',
  //   type: 'multiple',
  //   option: [
  //     {
  //       value: 'Passed',
  //       label: 'Passed'
  //     },
  //     {
  //       value: 'Failed',
  //       label: 'Failed'
  //     },
  //     {
  //       value: 'Unsure',
  //       label: 'Unsure'
  //     }
  //   ]
  // },
  // {
  //   label: 'Name Check Result',
  //   value: 'consumer_intelligence--authid--name_check_result',
  //   type: 'single'
  // },
  // {
  //   label: 'Address Check Result',
  //   value: 'consumer_intelligence--authid--address_check_result',
  //   type: 'single'
  // },
  // {
  //   label: 'ID Card Check Result',
  //   value: 'consumer_intelligence--authid--id_card_check_result',
  //   type: 'single'
  // },
  // {
  //   label: 'License # Check Result',
  //   value: 'consumer_intelligence--authid--license_check_result',
  //   type: 'single'
  // },
  // {
  //   label: 'Authentic ID Transaction Request Date/Time Stamp',
  //   value: 'consumer_intelligence--authid--authentic_id_transaction_request_stamp',
  //   type: 'single'
  // },
  // {
  //   label: 'Authentic ID Transaction Response Date/Time Stamp',
  //   value: 'consumer_intelligence--authid--authentic_id_transaction_response_stamp',
  //   type: 'single'
  // }
];

export const idemiaOptions: Option[] = [];

export const actionsFieldName: Option[] = [
  {
    label: 'IDV Check Result',
    value: 'idv_check_result'
  },
  {
    label: 'IDEMIA Result',
    value: 'idemia_result'
  },
  {
    label: 'Authentic ID Result',
    value: 'authentic_id_result'
  },
  {
    label: 'KYC Check Result',
    value: 'kyc_check_result'
  },
  {
    label: 'Aristotle Result',
    value: 'aristotle_result'
  },
  {
    label: 'IDology Result',
    value: 'idology_result'
  },
  {
    label: 'SOR Check Result',
    value: 'sor_check_result'
  },
  {
    label: 'IDEMIA SOR Result',
    value: 'idemia_sor_result'
  },
  {
    label: 'DL# Check Result',
    value: 'dl_check_result'
  },
  {
    label: 'Go to Provider',
    value: 'go_to_provider',
    option: providerOptions,
    type: 'single'
  },
  {
    label: 'Name Check Result',
    value: 'name_check_result'
  },
  {
    label: 'Front/Back Check Result',
    value: 'front_back_check_result'
  },
  {
    label: 'Exclusions Watch List Result',
    value: `exclusions_${WATCH_LIST_RESULT}`
  },
  {
    value: 'userApproved',
    label: 'User Approved',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'requestSSN9',
    label: 'Request SSN9',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'selfieMatchesId',
    label: 'Selfie Matches ID',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'requestSSN4',
    label: 'Request SSN4',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'requestResAddress',
    label: 'Request Residential Address',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'requestScanID',
    label: 'Request Scan ID',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: `pep_${WATCH_LIST_RESULT}`,
    label: 'PEP Watch List Result',
    type: 'single'
  },
  {
    value: `ofac_${WATCH_LIST_RESULT}`,
    label: 'OFAC Watch List Result',
    type: 'single'
  },
  {
    value: 'underAge',
    label: 'Under Age',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'expiredID',
    label: 'ID Expired',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'expiredDate',
    label: 'Expiry Date',
    type: 'single'
  },
  {
    value: 'failReason',
    label: 'Fail Reason',
    type: 'single'
  },
  {
    value: 'complianceReview',
    label: 'Compliance Review',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'ssn_first_five',
    label: 'SSN First 5',
    type: 'single'
  },

  {
    value: 'skip_next_provider',
    label: 'Skip Next Provider',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      },
      {
        value: 'false',
        label: 'False'
      }
    ]
  },
  {
    value: 'kyc_provider',
    label: 'KYC Provider',
    type: 'single'
  },
  {
    value: 'kyc_detailed_response',
    label: 'KYC Detailed Responses',
    type: 'single'
  },
  {
    value: 'orchestrationStatus',
    label: 'Orchestration Status',
    type: 'single',
    option: [
      {
        value: 'approved',
        label: 'Approved'
      },
      {
        value: 'denied',
        label: 'Denied'
      },
      {
        value: 'pending',
        label: 'Pending'
      }
    ]
  },
  {
    label: 'Status Message',
    value: 'status_message',
    type: 'single'
  },
  {
    label: 'Stop processing',
    value: 'stop_processing',
    type: 'single',
    option: [
      {
        value: 'true',
        label: 'True'
      }
    ]
  },
  {
    label: 'Name Check Result',
    value: 'name_check_result',
    type: 'single'
  },
  {
    label: 'SSN/TIN Check Result',
    value: 'ssn_tin_check_result',
    type: 'single'
  },
  {
    label: 'Address Check Result',
    value: 'address_check_result',
    type: 'single'
  },
  {
    label: 'Date of Birth Check Result',
    value: 'dob_check_result',
    type: 'single'
  },

  {
    label: 'Age Check Result',
    value: 'age_check_result',
    type: 'multiple',
    option: [
      {
        value: 'Passed',
        label: 'Passed'
      },
      {
        value: 'Failed',
        label: 'Failed'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    label: 'Message Age Check Result',
    value: 'message_age_check_result',
    type: 'single'
  }
];

export const systemVariables: Option[] = [
  {
    label: 'First Name',
    value: 'firstname',
    type: 'single'
  },
  {
    label: 'Middle Name',
    value: 'middle_name',
    type: 'single'
  },
  {
    label: 'Last Name',
    value: 'lastname',
    type: 'single'
  },
  {
    label: 'Address 1',
    value: 'address1',
    type: 'single'
  },
  {
    label: 'Address 2',
    value: 'address2',
    type: 'single'
  },
  {
    label: 'City',
    value: 'city',
    type: 'single'
  },
  {
    label: 'State/Province',
    value: 'state',
    type: 'single'
  },
  {
    label: 'Postal Code',
    value: 'postal_code',
    type: 'single'
  },
  {
    label: 'Country',
    value: 'country',
    type: 'single'
  },
  {
    label: 'Portrait Confidence Score',
    value: 'portrait_confidence_score',
    type: 'single',
    number: true
  },
  {
    label: 'SSN Type',
    value: 'ssnType',
    type: 'single',
    option: [
      {
        value: 'ssn_4',
        label: 'SSN 4'
      },
      {
        value: 'ssn_9',
        label: 'SSN 9'
      }
    ]
  },
  {
    label: 'Phone Number',
    value: 'phone',
    type: 'single'
  },
  {
    label: 'Email Address',
    value: 'email_address',
    type: 'single'
  },
  {
    label: 'Player Alias',
    value: 'player_alias',
    type: 'single'
  },
  {
    label: "Driver's License #",
    value: 'drivers_license_#',
    type: 'single'
  },
  {
    label: "Driver's License State",
    value: 'driver_license_state',
    type: 'single'
  },
  {
    label: 'National ID #',
    value: 'national_id_#',
    type: 'single'
  },
  {
    label: 'Date of Birth',
    value: 'date_of_birth',
    type: 'single'
  },
  {
    label: 'SSN',
    value: 'ssn',
    type: 'single'
  },
  {
    label: 'SSN4',
    value: 'ssn_4',
    type: 'single'
  },
  {
    label: 'SSN9',
    value: 'ssn_9',
    type: 'single'
  },
  {
    label: 'ITIN',
    value: 'itin',
    type: 'single'
  },
  {
    label: 'SIN',
    value: 'sin',
    type: 'single'
  },
  {
    label: 'Customer ID',
    value: 'customer_id',
    type: 'single'
  },
  {
    label: 'Player Alias/Username',
    value: 'player_alias_username',
    type: 'single'
  },
  {
    label: "Calculated Age from Driver's License",
    value: 'calculated_age',
    type: 'single',
    number: true
  },
  {
    label: "Driver's License DOB",
    value: 'drivers_license_dob',
    type: 'single'
  },

  {
    label: 'Age to Validate',
    value: 'age_to_validate',
    type: 'single',
    number: true
  },
  {
    label: 'Watch Alert',
    value: 'watch_alert',
    type: 'multiple',
    option: [
      {
        value: 'DENIED',
        label: 'DENIED'
      },
      {
        value: 'DECEASED',
        label: 'DECEASED'
      },
      {
        value: 'WATCH',
        label: 'WATCH'
      },
      {
        value: 'APPROVED',
        label: 'APPROVED'
      },
      {
        value: 'ITIN',
        label: 'ITIN'
      },
      {
        value: 'PO BOX',
        label: 'PO BOX'
      },
      {
        value: 'FAILED',
        label: 'FAILED'
      },
      {
        value: 'UNSURE',
        label: 'UNSURE'
      },
      {
        value: 'MC0',
        label: 'MC0'
      }
    ]
  },

  ...actionsFieldName
];

export const propertyOptions: Option[] = [];

export const actionPropertyOptions: Option[] = [
  ...aristotleOptions,
  ...idologyOptions,
  ...systemVariables,
  ...authenticOptions,
  ...idemiaOptions,
  ...actionsFieldName,
  ...propertyOptions
];

export const allProviders = [
  {
    label: 'Validation',
    value: 'VALIDATION'
  },
  ...providerOptions
];

export const getDefaultProvider = (id: number): DefaultProvider => {
  return {
    id,
    providerName: '',
    url: '',
    sid: '',
    checkbox: false,
    isCompleted: false,
    providerRules: [],
    enabled: true
  };
};

export const getDefaultProviderRule = (id: number): DefaultProviderRule => {
  return {
    id,
    name: '',
    description: '',
    isCompleted: false,
    conditions: [],
    actions: { update: [] },
    _id: null,
    enabled: true
  };
};

export const comparisonOptions = [
  {
    label: 'Less Than',
    value: 'lessThan'
  },
  {
    label: 'Less Than or Equal To',
    value: 'lessThanInclusive'
  },
  {
    label: 'Greater Than',
    value: 'greaterThan'
  },
  {
    label: 'Greater Than or Equal To',
    value: 'greaterThanInclusive'
  }
];

export const allowedTransports = {
  label: 'Allowed Transports',
  options: [
    {
      label: 'USB',
      value: 'usb'
    },
    {
      label: 'NFC',
      value: 'nfc'
    },
    {
      label: 'BLE',
      value: 'ble'
    },
    {
      label: 'Internal',
      value: 'internal'
    },
    {
      label: 'Hybrid',
      value: 'hybrid'
    }
  ],
  default: []
};

export const userVerification = {
  label: 'User Verification',
  options: [
    { label: 'Discouraged', value: 'discouraged' },
    { label: 'Preferred', value: 'preferred' },
    { label: 'Required', value: 'required' }
  ],
  default: 'preferred'
};
export const passkeyRegistrationFields = {
  userVerification,
  // Possible values for options.attestation
  attestationType: {
    options: [
      { label: 'None', value: 'none' },
      { label: 'Direct', value: 'direct' }
    ],
    label: 'Attestation',
    default: 'none'
  },
  // Possible values for options.attachment
  attachment: {
    options: [
      { label: 'All Supported', value: 'all' },
      { label: 'Cross-Platform', value: 'cross_platform' },
      { label: 'Platform', value: 'platform' }
    ],
    label: 'Attachments',
    default: 'all'
  },
  discoverableCredential: {
    options: [
      { label: 'Discouraged', value: 'discouraged' },
      { label: 'Preferred', value: 'preferred' },
      { label: 'Required', value: 'required' }
    ],
    label: 'Discoverable Credential',
    default: 'preferred'
  }
};
