import { Button, Grid } from '@mui/material';

interface FormFooterButtonsProps {
  onCancel: () => void;
  isEdit: boolean;
}

const FormFooterButtons = ({ onCancel, isEdit }: FormFooterButtonsProps) => {
  return (
    <Grid item style={{ width: '100%', textAlign: 'right' }}>
      <Button sx={{ mt: { xs: 2, md: 2 }, mr: 1 }} type="reset" variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button sx={{ mt: { xs: 2, md: 2 } }} variant="contained" type="submit">
        {isEdit ? 'Edit user' : 'Add user'}
      </Button>
    </Grid>
  );
};

export default FormFooterButtons;
