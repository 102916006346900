import axios from 'axios';

import { getAccessToken, getRefreshToken, getUserOrgId } from 'src/utils';

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'https://api-orchestration.devel.cams.private.id'
});

axiosInt.interceptors.request.use(
  async (config) => {
    config.headers.authorization = `Bearer ${getAccessToken()}`;
    config.headers.x_api_key = `${config?.data?.parent_api_key || config?.data?.user_apikey || getUserOrgId()}`;
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axiosInt.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 401) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        return axiosInt
          .post('/users/refresh-token', {
            refreshToken
          })
          .then((response) => {
            const { sessionToken, refreshToken } = response as any;
            const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
            localStorage.setItem(
              'userInfo',
              JSON.stringify({
                ...userInfo,
                sessionToken,
                refreshToken
              })
            );
            error.config.headers.authorization = `Bearer ${sessionToken}`;
            return axiosInt(error.config);
          })
          .catch((err) => {
            console.log(err);
            localStorage.removeItem('userInfo');
            window.location.replace(`/login?redirecturi=${window.location?.pathname}`);
          });
      } else {
        localStorage.removeItem('userInfo');
        window.location.replace(`/login?redirecturi=${window.location?.pathname}`);
      }
    }
    return Promise.reject(error.response);
  }
);

export default axiosInt;
