import { createSlice } from '@reduxjs/toolkit';

import { Rule, RulesStore } from 'src/types/rule';

import * as ruleStoresThunks from '../thunks/rule-stores';

export interface RulesStoreState {
  rulesStores: RulesStore[];
  rules: Rule[];
  isLoading: boolean;
  store: RulesStore | null;
}

const initialState: RulesStoreState = {
  rulesStores: [],
  isLoading: false,
  store: null,
  rules: []
};

const slice = createSlice({
  name: 'rulesStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ruleStoresThunks.getRulesStores.fulfilled, (state, { payload }) => {
      state.rulesStores = payload || [];
      state.isLoading = false;
    });
    builder.addCase(ruleStoresThunks.getRulesStores.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ruleStoresThunks.getRuleStore.fulfilled, (state, { payload }) => {
      state.store = payload;
      state.isLoading = false;
    });
    builder.addCase(ruleStoresThunks.getRuleStore.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ruleStoresThunks.getRulesFromStores.fulfilled, (state, { payload }) => {
      state.rules = payload;
      state.isLoading = false;
    });

    builder.addCase(ruleStoresThunks.getRulesFromStores.pending, (state) => {
      state.isLoading = true;
    });
  }
});

export default slice.reducer;
