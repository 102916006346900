import { createAsyncThunk } from '@reduxjs/toolkit';

import { IOrganization } from 'src/types/organization';

import API from '../api';

const module = 'organizations';
export const fetchOrganizations = createAsyncThunk(
  'POST/Get Organization',
  async (payload: object): Promise<IOrganization> => {
    try {
      return await API.post(`/${module}/getApikeyHierarchy`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const createOrganization = createAsyncThunk(
  'POST/Create Organization',
  async (payload: object): Promise<IOrganization> => {
    try {
      return await API.post(`/${module}/createCustomer`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'POST/Update Organization',
  async (payload: { id: string; body: object }): Promise<IOrganization> => {
    try {
      return await API.put(`/${module}/${payload.id}`, payload.body);
    } catch (err) {
      return err;
    }
  }
);

export const deleteOrganizationApi = createAsyncThunk(
  'POST/Delete Organization',
  async (payload: object): Promise<IOrganization> => {
    try {
      return await API.post(`/${module}/deleteCustomer`, payload);
    } catch (err) {
      return err;
    }
  }
);
