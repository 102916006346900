import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../api';

export const fetchAttempts = createAsyncThunk(
  'POST/Get Attempts',
  async ({ body: payload, query }: { body: any; query?: { page: number; limit: number } }) => {
    try {
      return await API.post(`/attempt/list`, payload, { params: query });
    } catch (err) {
      return err;
    }
  }
);
