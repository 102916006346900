import { createSlice } from '@reduxjs/toolkit';

import { IOrganization } from 'src/types/organization';

export interface OrganizationState {
  organizations: null | Array<IOrganization>;
}

const initialState: OrganizationState = {
  organizations: []
};

const slice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    getOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    addOrganizations: (state, action) => {
      state.organizations = [...state.organizations, action.payload];
    },
    updateOrganizations: (state, action) => {
      const id = action.payload.id;
      const orgs = [...state.organizations];
      const index = orgs.findIndex((i) => i._id === id);
      if (index > -1) {
        orgs[index] = action.payload.data;
      }
      state.organizations = orgs;
    },
    deleteOrganization: (state, action) => {
      state.organizations = state.organizations.filter((i) => i._id !== action.payload);
    }
  }
});

export const { addOrganizations, updateOrganizations, deleteOrganization, getOrganizations } = slice.actions;

export default slice.reducer;
