export enum Role {
  SuperAdmin = 'Super Admin',
  OrganizationAdmin = 'Organization Admin',
  Manager = 'Manager',
  StaffMember = 'Staff Member'
}

export const RoleWithHierarchy = {
  [Role.SuperAdmin]: 0,
  [Role.OrganizationAdmin]: 1,
  [Role.Manager]: 2,
  [Role.StaffMember]: 3
};

export const getRolesUnder = (role: Role): Role[] => {
  const roles = Object.values(Role);
  const roleIndex = RoleWithHierarchy[role];
  return roles.slice(roleIndex + 1);
};
