import checkoutFormModel from './checkoutFormModel';
const {
  formField: { organizationName }
} = checkoutFormModel;

export default {
  [organizationName.name.name]: '',
  [organizationName.defaultorganization.name]: '',
  [organizationName.providers.name]: []
};
