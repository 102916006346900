// import NextLink from 'next/link';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Badge, Button, Collapse, ListItem, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import clsx from 'clsx';
import { useContext, useState } from 'react';

import { Link } from 'react-router-dom';

import { SidebarContext } from 'src/contexts/SidebarContext';

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.black[100],
    color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow: '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.black[100]
  }
}));

export type SidebarMenuItemProps = {
  children?: React.ReactNode;
  active?: boolean;
  link?: string;
  badge?: string;
  badgeTooltip?: string;
  open?: boolean;
  name: string;
};

const SidebarMenuItem = ({
  children,
  link,
  badge,
  badgeTooltip,
  open: openParent = false,
  active = false,
  name,
  ...rest
}: SidebarMenuItemProps) => {
  const [menuToggle, setMenuToggle] = useState(openParent);
  const { closeSidebar } = useContext(SidebarContext);

  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          endIcon={menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
          onClick={toggleMenu}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
          {name}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Link
        to={link}
        style={{
          display: 'block',
          width: '100%',
          textDecoration: 'unset',
          marginTop: 5
        }}
      >
        <Button disableRipple component="a" className={clsx({ 'Mui-active': active })} onClick={closeSidebar}>
          {name}
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
        </Button>
      </Link>
    </ListItem>
  );
};

export default SidebarMenuItem;
