import { createSlice } from '@reduxjs/toolkit';

import { IProductGroup, ProductGroupWithVersions } from 'src/types/product-groups';
import { Rule } from 'src/types/rule';

import * as PGThunks from '../thunks/product-group';

export interface ProductGroupState {
  productGroups: IProductGroup[];
  rules: Rule[];
  activeProduct: IProductGroup;
  productGroupWithVersions: ProductGroupWithVersions;
  productGroupVersion: IProductGroup;
  isLoading: boolean;
}

const initialState: ProductGroupState = {
  productGroups: [],
  rules: [],
  activeProduct: null,
  productGroupWithVersions: null,
  productGroupVersion: null,
  isLoading: false
};

const slice = createSlice({
  name: 'productGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PGThunks.getProductGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productGroups = payload;
    });

    builder.addCase(PGThunks.getAuthProductGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.productGroups = payload;
    });
    builder.addCase(PGThunks.getProductGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(PGThunks.getAuthProductGroups.pending, (state) => {
      state.isLoading = true;
      state.productGroups = [];
    });
    builder.addCase(PGThunks.createProductGroup.fulfilled, (state, { payload }) => {
      state.productGroups = [...state.productGroups, payload];
      state.activeProduct = payload;
    });

    builder.addCase(PGThunks.getRules.fulfilled, (state, { payload }: { payload: any }) => {
      state.rules = payload;
    });
    builder.addCase(PGThunks.deleteProductGroup.fulfilled, (state, { payload }) => {
      state.productGroups = state?.productGroups?.filter?.((product) => product?._id !== payload);
    });
    builder.addCase(PGThunks.getProductGroupWithVersions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(PGThunks.getProductGroupWithVersions.fulfilled, (state, { payload }) => {
      state.productGroupWithVersions = payload;
    });

    builder.addCase(PGThunks.getProductGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(PGThunks.getProductGroup.fulfilled, (state, { payload }) => {
      state.productGroupVersion = payload;
    });

    builder.addCase(PGThunks.revertToVersion.fulfilled, (state, { payload }) => {
      state.productGroupWithVersions = {
        ...payload,
        previousVersions: payload.previousVersions?.sort((a, b) => a.version - b.version)
      };
    });
  }
});

export default slice.reducer;
