import { Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Page from 'src/content/Management/User/Page';
import PageHeader from 'src/content/ProductGroups/AddProductGroup/PageHeader';
import useQuery from 'src/hooks/useQuery';
import SidebarLayout from 'src/layouts/SidebarLayout';

function AddUser() {
  const [currentStep, setCurrentStep] = useState(1);
  const query = useQuery();
  //   const { query } = useRouter();
  const isEdit = query?.id;
  const navigate = useNavigate();
  const headerTitle = () => {
    switch (currentStep) {
      case 1:
      case 2:
        return `${isEdit ? 'Edit' : 'Add'} User`;
      default:
        return `${isEdit ? 'Edit' : 'Add'} Users`;
    }
  };
  return (
    <>
      <PageTitleWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/users/manage">
            Users
          </Link>
          <Link underline="hover" color="inherit" href="/users/manage">
            Manage
          </Link>
          <Typography color="text.primary">{isEdit ? 'Edit' : 'Add'} User</Typography>
        </Breadcrumbs>
        <PageHeader title={headerTitle()} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0}>
          <Grid item xs={12} mt={-2}>
            <Page currentStep={currentStep} setCurrentStep={setCurrentStep} navigate={navigate} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

AddUser.getLayout = (page) => <SidebarLayout>{page}</SidebarLayout>;

export default AddUser;
