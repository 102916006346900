import { createSlice } from '@reduxjs/toolkit';

import { ITransaction } from 'src/types/transaction';

import * as transactionsThunks from '../thunks/transactions';

export interface TransactionState {
  transactionsInLast30Minutes: ITransaction[];
  transactionsDetails: { data: ITransaction[]; totalCount: number };
  isLoading: boolean;
  transactionByTransactionId: ITransaction;
}

const initialState: TransactionState = {
  transactionsInLast30Minutes: [],
  transactionsDetails: { data: [], totalCount: 0 },
  isLoading: false,
  transactionByTransactionId: null
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(transactionsThunks.getTransactionsInLast30Minutes.fulfilled, (state, { payload }) => {
      state.transactionsInLast30Minutes = payload || [];
      state.isLoading = false;
    });
    builder.addCase(transactionsThunks.getTransactionsDetails.fulfilled, (state, { payload }) => {
      state.transactionsDetails = payload || { data: [], totalCount: 0 };
      state.isLoading = false;
    });
    builder.addCase(transactionsThunks.getTransactionsInLast30Minutes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(transactionsThunks.getTransactionsDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(transactionsThunks.getTransactionByTransactionId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(transactionsThunks.getTransactionByTransactionId.fulfilled, (state, { payload }) => {
      state.transactionByTransactionId = payload || null;
      state.isLoading = false;
    });
  }
});

export default slice.reducer;
