import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AccessControl from 'src/components/AccessControl';
import { Role } from 'src/constants/Roles';

const PageHeader = () => {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Users
        </Typography>
      </Grid>
      <Grid item>
        <AccessControl hiddenRoles={[Role.StaffMember]}>
          <Button
            onClick={() => navigate('/users/add-user')}
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Create user
          </Button>
        </AccessControl>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
