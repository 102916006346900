import { createSlice } from '@reduxjs/toolkit';

import { IAttempt } from 'src/types/Attempts';

import * as authThunks from '../thunks/authentications';

export interface TransactionState {
  attemptDetails: {
    data: IAttempt[];
    totalCount: number;
  };
  isLoading: boolean;
}

const initialState: TransactionState = {
  attemptDetails: { data: [], totalCount: 0 },
  isLoading: false
};

const slice = createSlice({
  name: 'authentications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authThunks.fetchAttempts.fulfilled, (state, { payload }) => {
      state.attemptDetails = payload || { data: [], totalCount: 0 };
      state.isLoading = false;
    });
    builder.addCase(authThunks.fetchAttempts.pending, (state) => {
      state.isLoading = true;
    });
  }
});

export default slice.reducer;
