// @flow
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import { FormikValues, useFormikContext } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormInput as Input } from 'src/components/FormInput';
import { SelectAutoComplete } from 'src/components/SelectAutoComplete';
import { getRolesUnder, Role } from 'src/constants/Roles';
import useToast from 'src/hooks/useToast';

import { AppDispatch, RootState } from 'src/redux/store';
import { resetPassword } from 'src/redux/thunks/users';
import { IOrganization } from 'src/types/organization';

const StepTwo = ({ formField, isEdit }: { formField: any; isEdit: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showToast } = useToast();
  const [password, setPassword] = useState('');
  const {
    users: { name, email, role, phone }
  } = formField;

  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const organizations = useSelector((state: RootState) => state.organizations.organizations);
  const defaultOrg = organizations?.find((org: IOrganization) => org?.value === values?.customerApiKey)?.name;
  const onReset = async () => {
    const body = {
      username: values?.email,
      password
    };
    const result: any = await dispatch(resetPassword(body));
    showToast('Password successfully reset', 'success');
  };
  return (
    <Card sx={{ minWidth: 275 }} style={{ width: '100%' }}>
      <CardHeader title={'User'} />
      <Divider />
      <CardContent>
        <InputLabel style={{ color: '#000' }}>Select Organization</InputLabel>
        <SelectAutoComplete
          options={organizations}
          name={'customerApiKey'}
          onChange={(e) => setFieldValue('customerApiKey', e)}
          value={defaultOrg}
          optionLabel={'name'}
          disabled={isEdit}
        />
        {/* <Input label={name.label} name={name.name} /> */}
        <Input label={email.label} name={email.name} disabled={isEdit} />
        {/* <Input label={phone.label} name={phone.name} isPhone={true} /> */}
        <InputLabel style={{ color: '#000', marginBottom: 10 }}> {role.label} </InputLabel>
        <Select
          onChange={(e) => setFieldValue('role', e.target.value)}
          fullWidth
          style={{ width: '100%' }}
          name={role.name}
          value={values.role || {}}
        >
          {/* @TODO - Get Roles under users role */}
          {getRolesUnder(Role.SuperAdmin).map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {isEdit && (
          <Grid container justifyContent="space-between" alignItems="center" mt={'20px'}>
            <Box flex={1} pr={'20px'}>
              <Input
                label={'Password'}
                type="password"
                name={'password'}
                value={password}
                onChange={(e) => setPassword(e?.target?.value)}
              />
            </Box>
            <Button sx={{ mt: 1, height: 53 }} variant="contained" onClick={onReset}>
              Reset Password
            </Button>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default StepTwo;
