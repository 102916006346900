import { createSlice } from '@reduxjs/toolkit';

import { IUser } from 'src/types/user';

export interface AuthState {
  user: null | IUser;
}

const initialState: AuthState = {
  user: JSON.parse(localStorage.getItem('userInfo'))
};

const slice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      state.user = action.payload;
    }
  }
});

export const { setUserInfo } = slice.actions;

export default slice.reducer;
