import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rule, RulesStore } from 'src/types/rule';

import API from '../api';

const module = 'v2/rules-store';

export const createRulesStore = createAsyncThunk('POST/RulesStore', async (payload: any): Promise<object> => {
  try {
    const res: object = await API.post(`/${module}`, payload);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const updateRulesStore = createAsyncThunk(
  'PUT/RulesStore',
  async (payload: { name: string; description: string; id: string }): Promise<object> => {
    try {
      const { id } = payload;
      const res: object = await API.put(`/${module}/${id}`, payload);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getRulesFromStores = createAsyncThunk('GET/RulesFromStores', async (): Promise<Rule[]> => {
  try {
    const res = (await API.get(`/${module}/rules`)) as Rule[];
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getRulesStores = createAsyncThunk('GET/RulesStores', async (): Promise<RulesStore[]> => {
  try {
    const res = (await API.get(`/${module}`)) as RulesStore[];
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const addRuleToStore = createAsyncThunk('POST/RuleToStore', async (payload: any): Promise<object> => {
  try {
    const { id } = payload;
    const res: object = await API.post(`/${module}/${id}/rule`, payload);
    return res;
  } catch (err) {
    console.log(err);
  }
});

export const getRuleStore = createAsyncThunk('GET/RuleStore', async (id: string): Promise<RulesStore> => {
  try {
    const res = (await API.get(`/${module}/${id}`)) as RulesStore;
    return res;
  } catch (err) {
    console.log(err);
  }
});
