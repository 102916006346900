import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITransactionUser } from 'src/types/user';

import API from '../api';

const module = 'users';
export const createUser = createAsyncThunk('POST/CreateUser', async (payload: object): Promise<ITransactionUser> => {
  try {
    return await API.post(`/${module}/initiateEnroll`, payload);
  } catch (err) {
    return err;
  }
});

export const fetchOrgUsers = createAsyncThunk(
  'POST/UserList',
  async (payload: { user_apikey: string }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/getUserList`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const verifyUser = createAsyncThunk(
  'POST/VerifyUser',
  async (payload: { uuid: string; token: string }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/verifyUser`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const getUserDetail = createAsyncThunk(
  'POST/UserDetail',
  async (payload: { uuid?: string; token?: string }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/getUserDetails`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const loginUser = createAsyncThunk(
  'POST/LoginUser',
  async (payload: { [key: string]: string | number }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/login`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const signupUser = createAsyncThunk(
  'POST/LoginUser',
  async (payload: { uuid?: string; token?: string; user_apikey: string }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/signup`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const deleteUserApi = createAsyncThunk(
  'POST/DeleteUser',
  async (payload: { [key: string]: string | number }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/deleteUser`, payload);
    } catch (err) {
      return err;
    }
  }
);

export const resetPassword = createAsyncThunk(
  'POST/UserList',
  async (payload: { username: string; password: string }): Promise<ITransactionUser> => {
    try {
      return await API.post(`/${module}/reset-password`, payload);
    } catch (err) {
      return err;
    }
  }
);
