import { Container, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/Management/User/PageHeader';
import UserTable from 'src/content/Management/User/UserTable';
import useQuery from 'src/hooks/useQuery';
import useToast from 'src/hooks/useToast';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { getUsers } from 'src/redux/slices/users';
import { AppDispatch, RootState } from 'src/redux/store';
import { deleteUserApi, fetchOrgUsers } from 'src/redux/thunks/users';
import { IUser } from 'src/types/user';

function UserManagement() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user: IUser = useSelector((state: RootState) => state.auth.user);
  const query = useQuery();
  const { showToast } = useToast();

  const getUsersList = async (organizationId?: string) => {
    const body = {
      user_apikey: organizationId || user?.apiKey
    };
    const result: any = await dispatch(fetchOrgUsers(body));
    if (result?.payload?.userList?.length) {
      dispatch(getUsers(result?.payload?.userList));
    } else {
      dispatch(getUsers([]));
    }
  };

  useEffect(() => {
    if (user?.apiKey) {
      getUsersList(query?.id as string);
    }
  }, [user]);

  const users = useSelector((state: RootState) => state.user.users);
  const organizations = useSelector((state: RootState) => state.organizations.organizations);

  const onDeleteUser = async (value: number | string, fieldToDelete: string) => {
    const body = {
      [fieldToDelete]: value
    };
    console.log('body:deleting user', body);
    await dispatch(deleteUserApi(body));
    getUsersList();
    showToast('User successfully deleted', 'success');
  };

  const onEditOrganization = (id: number | string) => {
    navigate({
      pathname: '/users/add-user',
      search: `?id=${id}`
    });
  };

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <UserTable
              users={users}
              organizations={organizations}
              onDeleteOrganization={onDeleteUser}
              onEditOrganization={onEditOrganization}
              getUsersList={getUsersList}
              orgId={query?.id}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

UserManagement.getLayout = (page) => <SidebarLayout>{page}</SidebarLayout>;

export default UserManagement;
