import { Grid } from '@mui/material';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import checkoutFormModel from 'src/constants/OrganizationModel/checkoutFormModel';
import formInitialValues from 'src/constants/OrganizationModel/formInitialValues';
import schema from 'src/constants/OrganizationModel/userSchema';
import useQuery from 'src/hooks/useQuery';
import useToast from 'src/hooks/useToast';
import { updateUser } from 'src/redux/slices/users';
import { RootState, useAppDispatch } from 'src/redux/store';
import { createUser } from 'src/redux/thunks/users';

import { IUser } from 'src/types/user';
import { formatPhoneInput } from 'src/utils/funcUtils';

import FormFooterButtons from './FormFooterButtons';

import StepTwo from './Steps/second';

const { formId, formField } = checkoutFormModel;
const Page = ({ currentStep, navigate }: any) => {
  const users = useSelector((state: RootState) => state.user.users);
  const user: IUser = useSelector((state: RootState) => state.auth.user);
  const formikRef = useRef<FormikProps<FormikValues>>();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { showToast } = useToast();
  const isEdit = query?.id?.length > 0;
  const currentValidationSchema = schema[currentStep - 1];

  const fetchProduct = async (id: string | string[]) => {
    if (typeof id === 'string') {
      const userWithEmail = users?.find((i: any) => i?.email === id);
      const user = users?.find((i: any) => i?.uuid === id) || userWithEmail;
      if (user) {
        formikRef?.current?.setValues({
          ...user,
          phone: formatPhoneInput(String(user?.phone || ''))
        });
      }
    }
  };

  useEffect(() => {
    if (query?.id) {
      fetchProduct(query?.id || '');
    }
  }, [query]);

  const RenderButtons = () => {
    // return
    return <FormFooterButtons onCancel={() => navigate('/users/manage')} isEdit={isEdit} />;
  };

  const handleSubmit = async (values) => {
    if (isEdit) {
      dispatch(updateUser({ id: values.id, data: values }));
    } else {
      const body = {
        role: values?.role,
        email: values?.email.trim().toLowerCase(),
        username: values?.email.trim().toLowerCase(),
        user_apikey: values?.customerApiKey || user?.apiKey,
        customerName: values?.username
      };
      const result: IUser | any = await dispatch(createUser(body));
      if (result?.payload?.message === 'Success') {
        showToast('User has been created successfully', 'success');
      }
      const status = result?.payload?.status;
      if (status > 400 && status < 500) {
        showToast('You are not authorized', 'error');
      }
    }
    navigate('/users/manage');
  };
  return (
    <Grid justifyContent="space-between" alignItems="center">
      <Formik
        initialValues={formInitialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
      >
        <Form id={formId}>
          <Grid item xs={12}>
            <StepTwo formField={formField} isEdit={isEdit} />
          </Grid>
          <Grid mb={2}>{RenderButtons()}</Grid>
        </Form>
      </Formik>
    </Grid>
  );
};

export default Page;
