import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, combineReducers, EmptyObject } from 'redux';
import logger from 'redux-logger';

import CommonReducer, { CommonState } from 'src/redux/slices/common';

import AuthReducer, { AuthState } from './slices/auth';
import AuthenticationsReducer from './slices/authentications';
import OrganizationReducer, { OrganizationState } from './slices/organizations';
import ProductGroupReducer, { ProductGroupState } from './slices/product-group';
import RulesReducer, { RulesStoreState } from './slices/rule-store';
import TransactionReducer, { TransactionState } from './slices/transactions';
import UsersReducer, { UsersState } from './slices/users';

const reducers = combineReducers({
  productGroups: ProductGroupReducer,
  auth: AuthReducer,
  organizations: OrganizationReducer,
  transactions: TransactionReducer,
  user: UsersReducer,
  common: CommonReducer,
  authentications: AuthenticationsReducer,
  rulesStore: RulesReducer
});

const middlewareConfig = {
  serializableCheck: false
};

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== 'production') {
      return getDefaultMiddleware(middlewareConfig).concat(logger);
    }
    return getDefaultMiddleware(middlewareConfig);
  }
});

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): ThunkDispatch<
  EmptyObject & {
    productGroups: ProductGroupState;
    auth: AuthState;
    organizations: OrganizationState;
    transactions: TransactionState;
    user: UsersState;
    common: CommonState;
    rulesStore: RulesStoreState;
  },
  undefined,
  AnyAction
> => useDispatch<AppDispatch>();

export const useAppSelector = <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => useSelector<RootState, TSelected>(selector, equalityFn);

export { store };
