import { createAsyncThunk } from '@reduxjs/toolkit';

import { ITransaction } from 'src/types/transaction';

import API from '../api';

const module = 'transaction';
export const getTransactionsInLast30Minutes = createAsyncThunk(
  'GET/TransactionsInLast30Minutes',
  async (): Promise<ITransaction[]> => {
    try {
      const res: ITransaction[] = await API.get(`/${module}/last-30-minutes`);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const downloadTransactions = createAsyncThunk(
  'GET/DownloadTransactions',
  async (
    body: Partial<ITransaction> & {
      createdUntil?: string;
      productGroupIds?: string;
    }
  ): Promise<ITransaction[]> => {
    try {
      const urlSearchParams = new URLSearchParams();
      Object.keys(body).forEach((key) => {
        if (body[key]) urlSearchParams.append(key, body[key]);
      });
      const res: ITransaction[] = await API.get(`v2/${module}/download?${urlSearchParams.toString()}`, {
        responseType: 'blob'
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);

export const downloadAuthenticationAttempts = createAsyncThunk(
  'GET/DownloadAuthenticationAttempts',
  async (transactionId: string): Promise<any> => {
    const res = await API.get(`/${module}/download/${transactionId}/auth`, {
      responseType: 'blob'
    });
    return res;
  }
);

export const getTransactionsDetails = createAsyncThunk(
  'GET/TransactionsDetails',
  async ({
    body,
    query
  }: {
    body: Partial<ITransaction> & {
      createdUntil?: string;
      productGroupIds?: string;
    };
    query?: { page: number; limit: number };
  }): Promise<{ data: ITransaction[]; totalCount: number }> => {
    try {
      return await API.post(`/${module}/search`, body, { params: query });
    } catch (err) {
      console.log(err);
    }
  }
);

export const getTransactionByTransactionId = createAsyncThunk(
  'GET/TransactionByTransactionId',
  async (transactionId: string): Promise<ITransaction> => {
    try {
      const res: ITransaction = await API.get(`/aggr/transaction/${transactionId}`);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);
