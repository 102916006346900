import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

function useQuery(): queryString.ParsedQuery<string> {
  const { search } = useLocation();

  return queryString.parse(search);
}

export default useQuery;
