import * as Yup from 'yup';

import checkoutFormModel from './checkoutFormModel';
const {
  formField: { organizationName, users }
} = checkoutFormModel;
const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

export default [
  Yup.object().shape({
    // username: Yup.string().required(`${users.name.requiredErrorMsg}`),
    email: Yup.string().email('Invalid email').required(`${users.email.requiredErrorMsg}`),
    // phone: Yup.string()
    //   .required(`${users.phone.requiredErrorMsg}`)
    //   .test('phone', 'Invalid phone number', (i: string) => phoneRegExp.test(i?.replace(/[^A-Z0-9]/gi, '')))
    //   .min(14, 'too short')
    //   .max(14, 'too long'),
    role: Yup.string().required(`${users.role.requiredErrorMsg}`)
  })
];
