export enum CamsProvider {
  ARISTOTLE = 'ARISTOTLE',
  IDOLOGY = 'IDOLOGY',
  AUTH_ID = 'AUTHENTIC_ID',
  VALIDATION = 'VALIDATION',
  EVALUATE1 = 'EVALUATE1',
  EVALUATE2 = 'EVALUATE2',
  EVALUATE3 = 'EVALUATE3',
  EVALUATE4 = 'EVALUATE4',
  EVALUATE5 = 'EVALUATE5',
  EVALUATE6 = 'EVALUATE6',
  PROPERTY_EXCLUSIONS = 'PROPERTY_EXCLUSIONS',
  IDEMIA = 'IDEMIA',
  OPEN_SANCTIONS = 'OPEN_SANCTIONS',
  IDEMIA_SOR_TXT = 'IDEMIA_SOR_TXT',
  AMAZON_TEXTRACT = 'AMAZON_TEXTRACT',
  AZURE_FORM_RECOGNIZER = 'AZURE_FORM_RECOGNIZER',
  PASSKEY_REGISTRATION = 'PASSKEY_REGISTRATION'
}

export interface IProductGroup {
  _id: string;
  pid: string;
  name: string;
  version: number;
  description: string;
  isDraft?: boolean;
  isProd?: boolean;
  apiValue: string;
  createdAt: string;
  providers: {
    providerName: string;
    providerId: string;
    sid: string;
    url: string;
    timeout: string;
    priority: number;
    rules: {
      description: string;
      name: string;
      enabled: boolean;
      _id: string;
      conditions: {
        all: {
          fact: string;
          id: number;
          operator: string;
          value: string;
          valueType: string;
        }[];
      };
      actions: {
        update: {
          value: string | string[];
          key: string;
        }[];
      };
    }[];
  }[];
}

export interface ProductGroupWithVersions {
  _id: string;
  current: IProductGroup;
  previousVersions: IProductGroup[];
}

export interface DefaultProvider {
  id: number;
  providerName: string;
  url: string;
  sid: string;
  checkbox: boolean;
  isCompleted: boolean;
  rules?: null[] | null;
  enabled: boolean;
  providerRules: any[];
}

export interface DefaultProviderRule {
  id: number;
  name: string;
  description: string;
  isCompleted: boolean;
  conditions?: null[] | null;
  actions?: { update: null[] | null };
  _id?: null;
  enabled: boolean;
}

export interface ProductGroupProduction {
  payload: {
    _id: string;
    data: {
      message: string;
    };
  };
}

export enum AuthCamsProvider {
  AUTH_VALIDATION = 'AUTH_VALIDATION',
  AMAZON_LOCATION_GEOFENCE = 'AMAZON_LOCATION_GEOFENCE',
  PASSKEY_AUTHENTICATION = 'PASSKEY_AUTHENTICATION'
}

export enum ProductGroupType {
  IDENTITY = 'identity',
  AUTHENTICATION = 'authentication'
}
