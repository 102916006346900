// import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// import { ENVIRONMENTS } from 'src/constants';
import { setApiHost } from 'src/redux/slices/common';

function EnvironmentDropdown() {
  const dispatch = useDispatch();
  const [
    selectValue
    // setSelectValue
  ] = useState('development');
  useEffect(() => {
    dispatch(setApiHost(selectValue));
  }, [selectValue]);
  return (
    <></>
    // <Box sx={{ mr: 1 }} style={{ display: 'flex', width: 300 }} alignItems="center">
    //   <InputLabel> Select Environment: </InputLabel>
    //   <Select
    //     style={{ width: 140, marginLeft: 20 }}
    //     value={selectValue}
    //     onChange={(e) => {
    //       setSelectValue(e?.target?.value);
    //     }}
    //   >
    //     {ENVIRONMENTS.map((option, index) => (
    //       <MenuItem key={index} value={option.value}>
    //         {option.label}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </Box>
  );
}

export default EnvironmentDropdown;
